import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';

import { APPROVE_SUGGESTED_ITEMS, CREATE_SUGGESTED_ITEMS, DELETE_SUGGESTED_ITEMS } from '../../services';
import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';

import { SUGGESTIONS } from '../../../../analytics/constants';

const useSuggestions = ({ suggestionsList = [], loaderData, updateQuery, tableIds }) => {
  const { track } = useAnalytics();

  const abortControllerRef = useRef(null);
  const [selectedTable, setSelectedTable] = useState(null);

  const [createSuggestedItems, { loading }] = useMutation(CREATE_SUGGESTED_ITEMS, {
    onCompleted: ({ createSuggestedTableItems }) => {
      updateQuery(cache => ({
        ...cache,
        tableItems: {
          ...cache.tableItems,
          itemsTable: {
            ...cache.tableItems.itemsTable,
            rowsUnderReview: createSuggestedTableItems.map(({ item }) => ({
              item,
              values: []
            }))
          }
        }
      }));
    },
  });

  const [approveSuggestedItems] = useMutation(APPROVE_SUGGESTED_ITEMS, {
    onCompleted: ({ approveSuggestedTableItem }) => {
      updateQuery(cache => ({
        ...cache,
        tableItems: {
          ...cache.tableItems,
          itemsTable: {
            ...cache.tableItems.itemsTable,
            rowsUnderReview: cache.tableItems.itemsTable.rowsUnderReview.filter(({ item }) => item.id !== approveSuggestedTableItem.item.id)
          }
        }
      }));
    }
  });

  const [deleteSuggestedItems] = useMutation(DELETE_SUGGESTED_ITEMS, {
    onCompleted: ({ deleteTableItems }) => {
      updateQuery(cache => ({
        ...cache,
        tableItems: {
          ...cache.tableItems,
          itemsTable: {
            ...cache.tableItems.itemsTable,
            rowsUnderReview: cache.tableItems.itemsTable.rowsUnderReview.filter((_, i) => !deleteTableItems[i])
          }
        }
      }));
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!tableIds?.includes(selectedTable) && loading){
      abortControllerRef.current?.abort();
    }
  }, [loading, selectedTable, tableIds]);

  const handleAddSuggestionValue = useCallback((data) => {
    updateQuery(cache => {
      const suggestionIndex = cache.tableItems.itemsTable.rowsUnderReview.findIndex(
        ({ item }) =>  item.id === data.tableItemId
      );
      const suggestion = cache.tableItems.itemsTable.rowsUnderReview[suggestionIndex];

      return {
        ...cache,
        tableItems: {
          ...cache.tableItems,
          itemsTable: {
            ...cache.tableItems.itemsTable,
            rowsUnderReview: cache.tableItems.itemsTable.rowsUnderReview.toSpliced(suggestionIndex, 1, {
              item: {
                ...suggestion.item,
                values: suggestion.item.values.concat(data)
              },
            })
          }
        }
      };
    });
  }, [updateQuery]);

  const handleCreateSuggestedItems = useCallback(async () => {
    try {
      abortControllerRef.current = new AbortController();
      setSelectedTable(tableIds[0]);

      await createSuggestedItems({
        variables: {
          tableId: tableIds[0]
        },
        context: {
          fetchOptions: {
            signal: abortControllerRef.current.signal
          }
        }
      });
    } catch (e) {
      if (!abortControllerRef.current?.signal.aborted){
        dispatch(
          openAppSnackbarNotification({
            variant: 'ERROR',
            message: e.message
          })
        );
      }
    } finally {
      abortControllerRef.current = null;
      setSelectedTable(null);
    }
  }, [createSuggestedItems, dispatch, tableIds]);

  const handleApproveSuggestedItems = useCallback(async (itemId) => {
    try {
      const data = await approveSuggestedItems({
        variables: {
          itemId
        }
      });

      updateQuery(cache => ({
        ...cache,
        tableItems: {
          ...cache.tableItems,
          itemsTable: {
            ...cache.tableItems.itemsTable,
            edges: [
              {
                cursor: '',
                node: data.data.approveSuggestedTableItem
              },
              ...cache.tableItems.itemsTable.edges,
            ]
          }
        }
      }
      ));

      track(SUGGESTIONS.accepted);
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [approveSuggestedItems, dispatch, track, updateQuery]);

  const handleReset = useCallback(async () => {
    try {
      await deleteSuggestedItems({
        variables: {
          ids: suggestionsList.map(({ item }) => item.id)
        }
      });
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [deleteSuggestedItems, dispatch, suggestionsList]);

  return {
    loading,
    data: !loading && suggestionsList ? suggestionsList.map(({ item }, i) => {
      return {
        ...item,
        table: loaderData.itemTablesById[item.table.id],
        values: item.values,
        isSuggestion: true,
        isLast: i === suggestionsList.length - 1
      };
    }) : [],
    onSuggestionsCreate: handleCreateSuggestedItems,
    onSuggestionApprove: handleApproveSuggestedItems,
    onReset: handleReset,
    onAddSuggestionValue: handleAddSuggestionValue
  };
};

export default useSuggestions;
