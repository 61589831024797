import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { COLOR_PRIMARY } from '../../styles';

const Table = ({ colgroups = [], children, className }) => (
  <table className={className}>
    {colgroups.map(({ id, colSpan }) => (
      <colgroup key={id} span={colSpan}></colgroup>
    ))}

    <tbody>
      {children}
    </tbody>
  </table>
);

Table.propTypes = {
  colgroups: PT.arrayOf(PT.shape({
    id: PT.string,
    colSpan: PT.number
  })),
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string
};

export default styled(Table)`
  border-collapse: collapse;
  border-width: 0 2px 2px 0;
  border-color: #DCDBDC;
  border-style: solid;

  flex: 1 0 auto;
  align-self: flex-start;

  colgroup:not(:last-child) {
    border-right: 1px solid #DCDBDC;
  }

  tr.head {
    height: 1px;
  }

  tr.line {
    --formulation-chips-color: #E0F7FA;

    &:hover {
      --formulation-chips-color: #B2EBF2;

      td {
        background-clip: padding-box;
      }
      
      .item-name-text > a {
        color: ${COLOR_PRIMARY};
        text-decoration: underline;
        cursor: pointer;
      }
        
      .add-suggestion-button {
        visibility: visible;
      }
    }
  }
`;
