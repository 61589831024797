import React, { useMemo } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import RawText from '../RawText';
import { parseToExponential } from '../../../../../../utils';
import { VALUE_TYPE } from '../../../../../../services/samples/constants';
import { isCertain } from '../../../../../../utils';

const ViewQuantityValue = ({
  valueType,
  data,
  prediction,
  uncertaintyUp,
  uncertaintyDown,
  displayPrediction,
  onClick
}) => {
  const displayValue = useMemo(() => {
    if(valueType === VALUE_TYPE.PREDICTION) {
      if(typeof data === 'number')
        return data;
      else
        return displayPrediction ? prediction : null;
    }

    return data;
  }, [valueType, data, prediction, displayPrediction]);

  const predictionColor = useMemo(() => {
    if(valueType !== VALUE_TYPE.PREDICTION || typeof data === 'number')
      return null;

    return isCertain(uncertaintyUp, uncertaintyDown, prediction) ? '#2E7D32' : '#EF5350';
  }, [valueType, data, prediction, uncertaintyUp, uncertaintyDown]);

  const predictionTooltip = useMemo(() => {
    if(valueType !== VALUE_TYPE.PREDICTION || typeof data === 'number')
      return data;

    return `${uncertaintyDown} - ${uncertaintyUp}`;
  }, [valueType, data, uncertaintyUp, uncertaintyDown]);

  const shortenedValue = displayValue && parseToExponential(displayValue);

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      onClick={onClick}
    >
      <Tooltip
        title={predictionTooltip}
        arrow
        placement="top"
      >
        <RawText
          hideTitleAttr
          sx={{ color: predictionColor }}
        >
          {shortenedValue}
        </RawText>
      </Tooltip>
    </Box>
  );
};

ViewQuantityValue.propTypes = {
  onClick: PT.func,
  valueType: PT.string,
  data: PT.oneOfType([PT.string, PT.number]),
  prediction: PT.number,
  uncertaintyUp: PT.number,
  uncertaintyDown: PT.number,
  displayPrediction: PT.bool
};

export default ViewQuantityValue;
