import React, { memo, useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Checkbox from '@mui/material/Checkbox';

import SortIcon from '../../SortIcon/';

import {
  TOOLBAR_HEIGHT_PX,
  FILTER_BAR_HEIGHT,
  TOOLBAR_MOBILE_VIEW_HEIGHT_PX
} from '../../../scenes/AllItems/constants';
import {
  CONTAINER_BORDER_WIDTH_PX,
  COLGROUP_HEADER_HEIGHT_PX,
  COL_HEADER_HEIGHT_PX
} from '../constants';
import { MEDIA_QUERIES_MOBILE } from '../../../styles';
import { TITLE_PANEL_WIDTH } from '../../PanelResizeControl/constants';
import PanelResizeControl from '../../PanelResizeControl/PanelResizeControl';
import { CELL_PADDING } from '../cells/TableCell';

const label = {
  inputProps: {
    'aria-label': 'Select all items'
  }
};

const ItemNameHeader = ({
  className,
  rowSpan = 1,
  colSpan = 1,
  checked,
  indeterminate,
  onCheckChange,
  sortParam,
  sortOrder,
  sortable,
  onSort,
  onUpdateTitleWidth,
  titleWidth
}) => {
  const handleCheckChange = useCallback((ev) => {
    onCheckChange(ev.target.checked);
  }, [onCheckChange]);

  const handleSort = useCallback(() => {
    if (sortable) {
      onSort({
        param: sortParam,
        order: sortOrder === 'ASC' ? 'DESC' : 'ASC',
      });
    }
  }, [sortParam, sortOrder, onSort, sortable]);

  return (
    <th
      className={className}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      <div
        className="header-container"
        style={{
          width: `${titleWidth + CELL_PADDING}px`
        }}
      >
        <div className="controls-container">
          <Checkbox
            {...label}
            disableRipple
            sx={{
              '&.MuiCheckbox-root': {
                py: 0,
                pl: 0,
                pr: '6px'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 16
              }
            }}
            checked={checked}
            indeterminate={indeterminate}
            onChange={handleCheckChange}
          />

          <span
            className="column-title"
            onClick={handleSort}
          >
            Name

            {sortable && <SortIcon order={sortOrder} />}
          </span>
        </div>
      </div>

      <PanelResizeControl
        panelWidth={titleWidth + CELL_PADDING}
        onResize={onUpdateTitleWidth}
        itemType={TITLE_PANEL_WIDTH}
      />
    </th>
  );
};

ItemNameHeader.propTypes = {
  className: PT.string,
  colSpan: PT.number,
  rowSpan: PT.number,
  checked: PT.bool,
  indeterminate: PT.bool,
  onCheckChange: PT.func,
  sorted: PT.bool,
  sortable: PT.bool,
  sortParam: PT.string,
  sortOrder: PT.string,
  onSort: PT.func,
  onUpdateTitleWidth: PT.func,
  titleWidth: PT.number
};

export default styled(memo(ItemNameHeader))`
  height: inherit;
  vertical-align: bottom;
  padding: 0;

  position: sticky;
  top: ${TOOLBAR_HEIGHT_PX + FILTER_BAR_HEIGHT}px;
  left: ${CONTAINER_BORDER_WIDTH_PX}px;
  z-index: 2;

  @media (${MEDIA_QUERIES_MOBILE}) {
    top: ${TOOLBAR_MOBILE_VIEW_HEIGHT_PX + FILTER_BAR_HEIGHT}px;
  }

  .header-container {
    height: ${COLGROUP_HEADER_HEIGHT_PX + COL_HEADER_HEIGHT_PX}px;
    padding: 0 6px;
    background: white;
    border-top: 2px solid #DCDBDC;
    box-sizing: border-box;

    display: flex;
    align-items: flex-end;
  }

  .controls-container {
    height: 20px;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  .column-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
  }
`;
