import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useAnalytics } from 'use-analytics';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import SortIcon, { SORTING_PARAMS } from '../../SortIcon';
import CalculationIcon from '../../Icons/CalculationIcon';
import AIFeatureIcon from '../../Icons/AIFeature';
import ColumnHeaderMenu from './ColumnHeaderMenu';
import PredictiveColumnSettingsDialog from '../../PredictiveColumnSettingsDialog';
import { COLOR_GREY_DARKER, COLOR_GREY_LIGHTER } from '../../../styles';
import { COL_HEADER_HEIGHT_PX } from '../constants';
import {
  sidebarSources,
  componentNames,
  TRACK_PREDICTIVE_COLUMNS,
  TRACK_PREDICTIVE_COLUMN_INFO
} from '../../../analytics/constants';

const PrimaryHeader = ({
  id,
  children,
  className,
  order,
  linkId,
  noAccess,
  calculation,
  valueType,
  predictionVisible,
  predictionSettings,
  onLinkClick,
  onSort,
  onRemove,
  onTogglePredictionVisibility,
  sortParam
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [predictionAnchorEl, setPredictionAnchorEl] = useState(null);
  const [predictionSettingsOpen, setPredictionSettingsOpen] = useState(false);

  const { track } = useAnalytics();

  const handleLinkClick = useCallback(ev => {
    // prevent sorting action to be called
    // when user clicks on the link
    ev.stopPropagation();

    onLinkClick({ id: linkId, source: sidebarSources.TABLE_COLUMN_NAME_LINK });
  }, [onLinkClick, linkId]);

  const handleActionsClick = useCallback(e => {
    e.stopPropagation();

    setMenuAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback((e) => {
    e.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const handleSort = useCallback(value => (e) => {
    e.stopPropagation();

    setMenuAnchorEl(null);

    onSort({
      order: value,
      featureId: id,
      param: sortParam
    });
  }, [id, onSort, sortParam]);

  const handleRemove = useCallback(e => {
    e.stopPropagation();

    setMenuAnchorEl(null);

    onRemove();
  }, [onRemove]);

  const handleOpenPredictionMenu = useCallback((ev) => {
    ev.stopPropagation();
    setPredictionAnchorEl(ev.currentTarget);
  }, []);

  const handleClosePredictionMenu = useCallback(() => {
    setPredictionAnchorEl(null);
  }, []);

  const handleTogglePredictionVisibility = useCallback((ev) => {
    ev.stopPropagation();

    handleClosePredictionMenu();
    onTogglePredictionVisibility(id);
    track(TRACK_PREDICTIVE_COLUMNS.toggle, {
      component: componentNames.TABLE_STRUCTURE
    });
  }, [id, onTogglePredictionVisibility, handleClosePredictionMenu, track]);

  const handleOpenPredictionSettings = useCallback(() => {
    handleClosePredictionMenu();
    setPredictionSettingsOpen(true);

    track(TRACK_PREDICTIVE_COLUMN_INFO.request);
  }, [handleClosePredictionMenu, track]);

  const handleClosePredictionSettings = useCallback(() => {
    setPredictionSettingsOpen(false);
  }, []);

  const linkProps = noAccess ?
    {
      sx: {
        color: '#9e9e9e',
        textDecorationColor: '#9e9e9e'
      }
    } :
    {
      onClick: handleLinkClick
    };

  const isActive = Boolean(order);
  const predictiveColumn = valueType === 'PREDICTION';

  return (
    <Box className={className}>
      {linkId ?
        <Link {...linkProps}>{children}</Link> :
        children
      }

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}
      >

        {calculation ?
          <Tooltip
            title={calculation.formula}
            placement="top"
            arrow
          >
            <CalculationIcon
              type={calculation.error ? 'ERROR_BADGE' : 'NORMAL'}
              sx={{
                fontSize: '12px',
                color: 'rgba(0,0,0,.6)'
              }}
            />
          </Tooltip> :
          null
        }

        {predictiveColumn ?
          <ButtonBase
            onClick={handleOpenPredictionMenu}
          >
            <AIFeatureIcon
              type={predictionVisible ? 'active' : 'disabled'}
              sx={{
                fontSize: '12px',
                cursor: 'pointer'
              }}
            />
          </ButtonBase> :
          null
        }

        {isActive && <SortIcon order={order} />}

        <MoreVertIcon
          onClick={handleActionsClick}
          sx={{
            fontSize: 16,
            color: isActive ? COLOR_GREY_DARKER : COLOR_GREY_LIGHTER,
            ':hover': {
              color: COLOR_GREY_DARKER
            }
          }}
        />

        <ColumnHeaderMenu
          anchorEl={menuAnchorEl}
          onClose={handleMenuClose}
          onSortAsc={sortParam && handleSort(SORTING_PARAMS.ASC)}
          onSortDesc={sortParam && handleSort(SORTING_PARAMS.DESC)}
          onRemove={handleRemove}
        />

        <Menu
          open={Boolean(predictionAnchorEl)}
          anchorEl={predictionAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          MenuListProps={{ dense: true }}
          onClose={handleClosePredictionMenu}
        >
          <MenuItem
            onClick={handleTogglePredictionVisibility}
          >
            {predictionVisible ? 'Hide' : 'Show'} prediction
          </MenuItem>

          <MenuItem
            onClick={handleOpenPredictionSettings}
          >
            Prediction settings
          </MenuItem>
        </Menu>

        {predictiveColumn ?
          <PredictiveColumnSettingsDialog
            open={predictionSettingsOpen}
            data={predictionSettings}
            onClose={handleClosePredictionSettings}
          /> :
          null
        }
      </Box>
    </Box>
  );
};

PrimaryHeader.propTypes = {
  id: PT.string,
  sortParam: PT.string,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  isDefaultColumn: PT.bool,
  className: PT.string,
  sortable: PT.bool,
  order: PT.oneOf(Object.keys(SORTING_PARAMS)),
  linkId: PT.string,
  calculation: PT.shape({
    formula: PT.string,
    error: PT.string
  }),
  valueType: PT.string,
  predictionVisible: PT.bool,
  predictionSettings: PT.shape({
    title: PT.string,
    model: PT.object
  }),
  noAccess: PT.bool,
  onLinkClick: PT.func,
  onSort: PT.func,
  onRemove: PT.func,
  onTogglePredictionVisibility: PT.func
};

export default styled(PrimaryHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: ${COL_HEADER_HEIGHT_PX / 2}px;

  a {
    overflow: hidden;
  }
`;
