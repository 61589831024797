import { gql } from '@apollo/client/core';

export const UPDATE_FEATURE_VALUE = gql`
  mutation Mutation($input: UpdateTableValueInput!) {
    updateTableValue(input: $input) {
      dependentTableValues {
        tableParameterId
        type
        tableItemId

        ... on TableValueQuantity {
          quantity
        }

        ... on TableValueCalculation {
          quantity
          calculationError
        }

        ... on TableValueText {
          text
        }

        ... on TableValueBoolean {
          boolean
        }

        ... on TableValueLink {
          linkedTableItem {
            id
            code
            title
            viewerMaxRole
          }
        }

        ... on TableValueEnum {
          enumValues {
            id
            title
          }
        }
      }
      tableValue {
        featureId
        tableParameterId
        type
        tableItemId
        
        ... on TableValueQuantity {
          quantity
        }

        ... on TableValueCalculation {
          quantity
          calculationError
        }

        ... on TableValueText {
          text
        }

        ... on TableValueBoolean {
          boolean
        }

        ... on TableValueLink {
          linkedTableItem {
            id
            code
            title
            viewerMaxRole
          }
        }

        ... on TableValueEnum {
          enumValues {
            id
            title
          }
        }
        
        ... on TableValuePrediction {
          quantity
        }
      }
    }
  }
`;

