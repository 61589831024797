import { useCallback, useReducer } from 'react';

const actions = {
  SET_TITLE_VALUE: 'SET_TITLE_VALUE',
  SET_TITLE_ERROR: 'SET_TITLE_ERROR',
  SET_TABLES_ERROR: 'SET_TABLES_ERROR',
  SET_DESCRIPTION_VALUE: 'SET_DESCRIPTION_VALUE',
  SET_TABLES: 'SET_TABLES',
  REMOVE_TABLE: 'REMOVE_TABLE',
  SET_UNIT: 'SET_UNIT',
  RESET: 'RESET'
};

const createInitialState = (config) => {
  return {
    title: {
      value: config?.title ?? '',
      error: null
    },
    description: {
      value: config?.description ?? '',
    },
    tables: {
      value: config?.titleTables ?? [],
      error: null
    },
    unit: {
      value: config?.unit ?? null,
    },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_TITLE_VALUE:
      return {
        ...state,
        title: {
          ...state.title,
          error: null,
          value: action.payload
        }
      };

    case actions.SET_TITLE_ERROR:
      return {
        ...state,
        title: {
          ...state.title,
          error: action.payload,
        }
      };

    case actions.SET_TABLES_ERROR:
      return {
        ...state,
        tables: {
          ...state.tables,
          error: action.payload,
        }
      };

    case actions.SET_DESCRIPTION_VALUE:
      return {
        ...state,
        description: {
          ...state.description,
          error: null,
          value: action.payload
        }
      };
    case actions.SET_TABLES:
      return {
        ...state,
        tables: {
          value: action.payload,
          error: null
        }
      };
    case actions.REMOVE_TABLE:
      return {
        ...state,
        tables: {
          value: state.tables.value.filter(table => table.id !== action.payload)
        }
      };
    case actions.SET_UNIT:
      return {
        ...state,
        unit: {
          value: action.payload
        }
      };
    case actions.RESET:
      return createInitialState(action.payload);
    default:
      return state;
  }
};

const useTableFormulationFormHook = (config, onDataChanged) => {
  const [state, dispatch] = useReducer(reducer, config, createInitialState);

  const setTitleValue = useCallback(value => {
    dispatch({
      type: actions.SET_TITLE_VALUE,
      payload: value.target.value
    });

    onDataChanged?.(true);
  }, [onDataChanged]);

  const setTitleError = useCallback(value => {
    dispatch({
      type: actions.SET_TITLE_ERROR,
      payload: value
    });
  }, []);

  const setTablesError = useCallback(value => {
    dispatch({
      type: actions.SET_TABLES_ERROR,
      payload: value
    });
  }, []);

  const setDescriptionValue = useCallback(value => {
    dispatch({
      type: actions.SET_DESCRIPTION_VALUE,
      payload: value.target.value
    });

    onDataChanged?.(true);
  }, [onDataChanged]);

  const setTables = useCallback(value => {
    dispatch({
      type: actions.SET_TABLES,
      payload: value
    });

    onDataChanged?.(true);
  }, [onDataChanged]);

  const removeTable = useCallback(value => {
    dispatch({ type: actions.REMOVE_TABLE, payload: value });

    onDataChanged?.(true);
  }, [onDataChanged]);

  const setUnit = useCallback(value => {
    dispatch({ type: actions.SET_UNIT, payload: value });

    onDataChanged?.(true);
  }, [onDataChanged]);

  const resetState = useCallback(() => {
    dispatch({ type: actions.RESET, payload: config });

    onDataChanged?.(false);
  }, [config, onDataChanged]);

  return {
    state,
    setTitleValue,
    setTitleError,
    setTablesError,
    setDescriptionValue,
    setTables,
    removeTable,
    setUnit,
    resetState
  };
};

export default useTableFormulationFormHook;
