import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const SuggestionsWarningDialog = ({ open, onCancel, onSubmit }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onCancel}
    >
      <DialogContent>
        <DialogContentText>
          Warning: This table has unsaved suggestions! Generating new suggestions will delete any unsaved suggestions
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onCancel}
        >
          Go Back
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SuggestionsWarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SuggestionsWarningDialog;
