import React, { forwardRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const CELL_PADDING_X = 6;
export const CELL_PADDING = CELL_PADDING_X * 2;

const TableCell = forwardRef(({ children, className, ...props }, ref) => (
  <td ref={ref} className={className} {...props}>
    {children}
  </td>
));

TableCell.propTypes = {
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string,
  suggestions: PT.object,
};

export default styled(TableCell).withConfig({
  shouldForwardProp: (prop) => !['maxWidth', 'value', 'suggestions'].includes(prop)
})`
  vertical-align: middle;
  max-width: ${({ maxWidth }) => maxWidth != null ? `${maxWidth}px` : 'none'};
    
${({ suggestions }) => {
    let styles = 'padding: 0 0;';
    if (suggestions) {
      styles += `
        & > :first-child {
          height: 40px;
          line-height: 38px;
          display: flex;
          align-items: center;
        `;

      if (suggestions.isFirstSuggestionCell) {
        styles += `border-left: 3px solid #2196F3;`;
      }

      if (suggestions.isLastSuggestionCell) {
        styles += `border-right: 3px solid #2196F3;`;
      }

      if (suggestions.isLastSuggestion) {
        styles += `border-bottom: 3px solid #2196F3;`;
      }

      styles += `}`;
    }

    return styles;
  }}
`;
