import styled from 'styled-components';
import { COLOR_PRIMARY_LIGHT } from '../../../../styles';

export const StyledRow = styled('tr').attrs({ className: 'line' })`
  border-top: 1px solid ${({ isSuggestion }) => isSuggestion ? '#2196F3' : '#DCDBDC'};
  ${({ isSuggestion }) => isSuggestion && `
    td {
      background-color: rgba(250,251,255,255);
    }
  `}
  :hover {
    background: ${({ isSuggestion }) => !isSuggestion ? 'rgb(244, 244, 244)' : 'rgba(250,251,255,255)'};
    td {
      background: ${({ isSuggestion }) => !isSuggestion ? 'rgb(244, 244, 244)' : 'rgba(250,251,255,255)'};
    }
  }
  ${({ chosen }) => chosen && `
    background-color: rgb(244, 244, 244);
    td:first-child {
      background-color: rgb(244, 244, 244);
      box-shadow: inset 2px 0 ${COLOR_PRIMARY_LIGHT};
    }
  `}
`;

