import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import UnitsSelect from '../../../../../../../components/UnitsSelect';
import FormulationSelect from './FormulationSelect';
import Enum from './Enum';

import { parameterTypes, parameterTitleTypes } from './constants';

const { ENUM } = parameterTypes;

const ContentItemValue = ({
  parameterId,
  columns,
  data,
  disabled,
  onChange,
  title
}) => {
  switch(data.type) {
    case 'TEXT':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          User can enter free text
        </Typography>
      );

    case 'BOOLEAN':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          User can select "Yes" or "No"
        </Typography>
      );

    case 'LINK':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          User can select any link to item
        </Typography>
      );

    case 'QUANTITY':
      return (
        <UnitsSelect
          value={data.value?.unit}
          disabled={disabled}
          onChange={onChange}
        />
      );

    case 'CALCULATION':
      return (
        <FormulationSelect
          parameterId={parameterId}
          columns={columns}
          value={data.value}
          disabled={disabled}
          onChange={onChange}
        />
      );

    case ENUM.id: {
      const { type: titleType, value: titleValue } = title;
      const isLink = titleType === parameterTitleTypes.LINK;
      const titleText = isLink ? titleValue?.label : titleValue;

      return (
        <Enum
          parameterTitle={titleText}
          value={data.value}
          onChange={onChange}
        />
      );
    }

    case 'PREDICTION':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          Predictive value
        </Typography>
      );

    default:
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          Unknown type
        </Typography>
      );
  }
};

ContentItemValue.propTypes = {
  parameterId: PT.string,
  columns: PT.array.isRequired,
  title: PT.shape({
    type: PT.oneOf([
      parameterTitleTypes.LINK,
      parameterTitleTypes.TEXT
    ]),
    value: PT.oneOfType([
      PT.string,
      PT.shape({ label: PT.string })
    ])
  }),
  data: PT.shape({
    type: PT.oneOf([
      'TEXT',
      'BOOLEAN',
      'QUANTITY',
      'LINK',
      'CALCULATION',
      ENUM.id
    ]),
    value: PT.oneOfType([
      PT.shape({ enumValueOptions: PT.array }),
      // units for the 'QUANTITY' type
      PT.shape({
        unit: PT.object,
        calculation: PT.object,
        description: PT.string
      })
    ])
  }),
  disabled: PT.bool,
  onChange: PT.func
};

export default ContentItemValue;
