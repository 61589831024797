import React, { memo, useCallback } from 'react';
import { useAnalytics } from 'use-analytics';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';

// TODO: There was a problem that caused a significant rendering delay
// when many columns displayed in the table.
// The "hotfix" solution is to not render the component when it is not
// in the view. But the real solution should include further investigation
// of the issue and reducing the number of computation in each cell
// (e.g., user permissions, styles, etc.)
import { useInView } from 'react-intersection-observer';

import TableCell from '../TableCell';
import { componentNames, TRACK_FORMULATION } from '../../../../analytics/constants';

const shortenQuantity = (quantity) => {
  return quantity < 0.01 ? '< 0.01' : new Intl.NumberFormat('en-US',
    { maximumFractionDigits: 2 }).format(quantity);
};

const FormulationValue = (props) => {
  const { track } = useAnalytics();

  const { ref, inView } = useInView();
  const { protocolId, ingredients } = props.getValue();

  const openSidebar = useCallback(() => {
    props.table.customState.handleToggleSidebar({
      id: props.row.id,
      protocol: protocolId
    });

    track(TRACK_FORMULATION.edit, {
      component: componentNames.TABLE_STRUCTURE
    });
  }, [props.row.id, props.table.customState, protocolId, track]);

  return (
    <TableCell ref={ref} value suggestions={props.suggestions}>
      {inView ?
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          px="6px"
          sx={{
            '&:hover': {
              '.edit-formulation-button': {
                visibility: 'visible'
              }
            }
          }}
        >
          <Box
            display="flex"
            gap="6px"
          >
            {ingredients.map(({ quantity, parameter }) => {
              const { titleTableItem, tableProtocol } = parameter;
              const ingredientName = titleTableItem.title;
              const unitName = tableProtocol.unit?.name;

              return (
                <Box
                  key={ingredientName}
                  p="3px 4px"
                  borderRadius="6px"
                  sx={{
                    background: 'var(--formulation-chips-color)'
                  }}
                  fontSize="12px"
                  lineHeight="16px"
                >
                  {shortenQuantity(quantity)}{unitName} {ingredientName}
                </Box>
              );
            })}
          </Box>

          {protocolId && !props.row.isSuggestion ?
            <IconButton
              disableRipple
              className="edit-formulation-button"
              sx={{
                visibility: 'hidden',
                p: 0,
                pl: '6px',
              }}
              onClick={openSidebar}
            >
              <EditIcon
                sx={{
                  fontSize: '16px',
                  color: '#8C8C8C'
                }}
              />
            </IconButton>
            : null
          }
        </Box>
        : null
      }
    </TableCell>
  );
};

FormulationValue.propTypes = {
  getValue: PT.func,
  table: PT.object,
  row: PT.object,
  column: PT.object,
  suggestions: PT.object,
};

export default memo(FormulationValue);
