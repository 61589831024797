import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const CloseEditingDialog = ({ open, onCancel, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        Warning: Discard unsaved changes to this protocol?
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onCancel}
        >
          Go back
        </Button>

        <Button
          onClick={onSubmit}
        >
          Discard unsaved changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CloseEditingDialog.propTypes = {
  open: PT.bool.isRequired,
  onCancel: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
};

export default CloseEditingDialog;
