import React, { useCallback, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import { FOLDERS, TABLES } from './gql';

import mapFoldersAndTablesTree from '../../../../../../../../utils/mapFoldersAndTablesTree';
import FoldersList from '../../../../../../../../components/FoldersList';
import Divider from '@mui/material/Divider';

const TablesDialog = ({ initialValue, onCancel, onSubmit }) => {
  const { data: foldersData } = useQuery(FOLDERS);
  const { data: tablesData } = useQuery(TABLES);

  const { folders } = foldersData ?? {};
  const { tables } = tablesData ?? {};

  const [search, setSearch] = useState('');
  const [chosenTables, setChosenTables] = useState(initialValue);

  const handleSearch = useCallback((ev) => {
    setSearch(ev.target.value);
  }, []);

  const handleCheck = useCallback((ids, checked) => {
    setChosenTables(s => {
      if (checked){
        return uniq([
          ...s,
          ...ids
        ]);
      } else {
        return difference(s, ids);
      }
    });
  }, []);

  const handleSubmit = useCallback(() => {
    const list = tables.filter(table => chosenTables.includes(table.id));

    onSubmit(list);
  }, [chosenTables, onSubmit, tables]);

  const data = useMemo(() => {
    return mapFoldersAndTablesTree(folders, tables, search);
  }, [folders, tables, search]);

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Select Tables
      </DialogTitle>

      <DialogContent>
        <FormControl
          sx={{
            border: '1px solid #D2D2D2',
            borderRadius: '6px',
            padding: '4px 8px',
          }}
          fullWidth
        >
          <InputBase
            value={search}
            onChange={handleSearch}
            endAdornment={<SearchIcon fontSize="16px" />}
            placeholder="Search"
            sx={{
              p: 0
            }}
          />
        </FormControl>

        <Box
          sx={{
            margin: '16px 0',
            height: '400px',
            overflowX: 'auto',
          }}
        >
          <FoldersList
            onCheck={handleCheck}
            data={data}
            isFiltered={Boolean(search)}
            value={chosenTables}
          />
        </Box>

        <Divider />
      </DialogContent>

      <DialogActions
        sx={{
          padding: '0 20px 22px',
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          cancel
        </Button>

        <Button
          onClick={handleSubmit}
          variant="contained"
        >
          select {chosenTables.length} tables
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TablesDialog.propTypes = {
  initialValue: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TablesDialog;
