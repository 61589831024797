import React, { memo, forwardRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import { COL_MAX_WIDTH_PX } from '../../../constants';

const RawText = forwardRef(({ children, title, hideTitleAttr, ...props }, ref) => (
  <Typography
    ref={ref}
    {...props}

    noWrap
    variant="body2"
    title={hideTitleAttr ? null : (title || children)}
  >
    {children}
  </Typography>
));

RawText.propTypes = {
  title: PT.oneOfType([
    PT.string,
    PT.number
  ]),
  hideTitleAttr: PT.bool,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ])
};

export default styled(memo(RawText))`
  max-width: ${COL_MAX_WIDTH_PX}px;
`;
