import React, { useCallback, useContext, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import { PARAMETER_VALUE_TYPES } from '../constants';
import { filterTypes } from '../../FiltersPopper/utils';
import { COLOR_GREY_LIGHTER } from '../../../../../../styles';
import { PreventClosingContext } from '../../FiltersPopper/PreventClosingContext';

const useStyles = makeStyles({
  chipsInput: {
    '&.MuiTextField-root': {
      minHeight: 32,

      '& .MuiInputBase-root': {
        paddingRight: '42px',
      }
    },

    '&.MuiTextField-root .MuiInputBase-root': {
      flexGrow: 1,
      padding: '2px',

      '& .MuiSvgIcon-root': {
        width: '18px',
        height: '18px'
      },

      '& .MuiInputBase-input': {
        padding: 0,
        paddingLeft: 7,
      },

      '& .MuiChip-root': {
        marginLeft: '4px',
        marginTop: '2px',
        marginBottom: '2px',
        borderRadius: '5px',
      }
    },
  },
  chip: {
    height: '22px',

    '& .MuiChip-label': {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
  },
  chipIcon: {
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '12px'
    }
  }
});

const GET_VIEWER = gql`
  query NewCommentViewerInfo {
    viewer {
      id
      firstName
      lastName
      userName
    }
  }
`;

const ChipsInput = ({ value, onChange, parameter, type }) => {
  const { setPreventClosing } = useContext(PreventClosingContext);

  const { data: viewerData } = useQuery(GET_VIEWER, {
    fetchPolicy: 'cache-first'
  });

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = useCallback((e, reason) => {
    if (['blur', 'escape'].includes(reason) || reason === 'toggleInput' && e.target instanceof SVGElement){
      setPreventClosing(true);
      setOpen(false);
      setTimeout(() => setPreventClosing(false), 300);
    }
  }, [setPreventClosing]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleChange = useCallback((event, newValue) => {
    onChange(newValue.map(i => {
      if ([filterTypes.CREATOR_IDS, PARAMETER_VALUE_TYPES.BOOLEAN, PARAMETER_VALUE_TYPES.ENUM].includes(type))
        return i.value;

      return i.label;
    }));
  }, [onChange, type]);

  const parameterValuesKey = (data, type) => {
    switch(type) {
      case filterTypes.CREATOR_IDS: {
        const newData = data.map(i => ({ label: `${i.firstName} ${i.lastName}`, value: i.userId, userName: i.userName }))
          .toSpliced(data.findIndex(user => user.userId === viewerData?.viewer.id), 1)
          .sort((a, b) => a.label.localeCompare(b.label));

        return [{
          label: `${viewerData?.viewer.firstName} ${viewerData?.viewer.lastName}`,
          value: viewerData?.viewer.id,
          userName: viewerData?.viewer.userName
        },
        ...newData
        ];
      }
      case PARAMETER_VALUE_TYPES.TEXT:
        return data.textValues.map(i => ({ label: i }));

      case PARAMETER_VALUE_TYPES.ENUM:
        return data.enumValueOptions.map(i => ({ label: i.title, value: i.id }));

      case PARAMETER_VALUE_TYPES.BOOLEAN:
        return [
          { value: true,
            label: 'Yes',
            disabled: !data.boolean.includes(true)
          },
          {
            value: false,
            label: 'No',
            disabled: !data.boolean.includes(false)
          }
        ];
    }
  };

  const values = parameterValuesKey(parameter, type);

  const chosenValues = useMemo(() => {
    if(!value){
      return [];
    } else if (type === PARAMETER_VALUE_TYPES.BOOLEAN) {
      return value.map(i => {
        return { value: i, label: i ? 'Yes' : 'No' };
      }).filter(i => map(values, 'label').includes(i.label));
    } else if ([filterTypes.CREATOR_IDS, PARAMETER_VALUE_TYPES.ENUM].includes(type)) {
      return value.map(id => ({ value: id, label: values.find(i => id === i.value).label }))
        .filter(i => map(values, 'label').includes(i.label));
    }

    return value.map(i => {
      return { label: i };
    }).filter(i => map(values, 'label').includes(i.label));
  }, [type, value, values]);

  const filterOptions = useCallback((options, { inputValue }) => {
    if (!inputValue)
      return options;

    return options.filter(i => i.label.toLowerCase().includes(inputValue?.toLowerCase()) ||
      i.userName?.toLowerCase().includes(inputValue?.toLowerCase()));
  }, []);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      limitTags={1}
      clearIcon={null}
      isOptionEqualToValue={(a, b) => {
        if (type === filterTypes.CREATOR_IDS)
          return a.value === b.value;

        return a.label.toLowerCase() === b.label.toLowerCase();
      }}
      options={(values ?? [])}
      filterOptions={filterOptions}
      onChange={handleChange}
      value={chosenValues}
      renderTags={(tagValue, getTagProps, ownerState) => {
        if (ownerState.focused)
          return null;

        if (tagValue.length === 1)
          return (
            <Typography
              sx={{
                paddingLeft: '4px'
              }}
              noWrap
            >
              {tagValue[0].label}
            </Typography>
          );

        return (
          <Typography
            sx={{
              paddingLeft: '4px'
            }}
          >
            {`${tagValue.length} selected`}
          </Typography>
        );
      }
      }
      renderInput={(params) => {
        let secondaryStyles = null;

        if (!open && value?.length)
          secondaryStyles = {
            '&.MuiTextField-root .MuiInputBase-root': {
              '& .MuiInputBase-input': {
                height: 0
              },
            }
          };

        return (
          <TextField
            {...params}
            className={classes.chipsInput}
            sx={secondaryStyles}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option.value ?? option.label;
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={option.value ?? option.label}
          style={{ padding: '4px' }}
        >
          <Checkbox
            style={{
              marginRight: 4,
              color: option.disabled ? COLOR_GREY_LIGHTER : 'primary',
            }}
            checked={selected}
          />

          <Box>
            <Tooltip title={option.userName}>
              <Typography
                noWrap
                sx={{
                  color: option.disabled ? COLOR_GREY_LIGHTER : 'initial',
                }}
              >
                {option.label}
              </Typography>
            </Tooltip>
          </Box>
        </li>
      )}
    />
  );
};

ChipsInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  parameter: PropTypes.shape({
    textValues: PropTypes.array,
    enumValueOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    }))
  })
};

export default ChipsInput;
