import React, { useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import {
  TOOLBAR_HEIGHT_PX,
  FILTER_BAR_HEIGHT,
  TOOLBAR_MOBILE_VIEW_HEIGHT_PX
} from '../../../scenes/AllItems/constants';
import {
  COLGROUP_HEADER_HEIGHT_PX,
  COL_HEADER_HEIGHT_PX
} from '../constants';
import { MEDIA_QUERIES_MOBILE } from '../../../styles';

const GroupColumnHeader = ({
  children,
  className,
  colSpan = 1,
  rowSpan = 1,
  id,
  sortParam,
  sortOrder,
  onSort
}) => {
  const handleSort = useCallback(() => {
    if (!sortOrder) return;

    onSort({
      param: sortParam,
      order: sortOrder === 'ASC' ? 'DESC' : 'ASC',
      featureId: id
    });
  }, [id, sortParam, sortOrder, onSort]);

  return (
    <th
      className={className}
      colSpan={colSpan}
      rowSpan={rowSpan}
      onClick={handleSort}
    >
      <div className="header-container">
        <span className="column-title">
          {children}
        </span>
      </div>
    </th>
  );
};

GroupColumnHeader.propTypes = {
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string,
  colSpan: PT.number,
  rowSpan: PT.number,
  id: PT.string,
  sortParam: PT.string,
  sortOrder: PT.string,
  predictionVisible: PT.bool,
  onSort: PT.func
};

export default styled(GroupColumnHeader)`
  height: inherit;
  vertical-align: bottom;
  padding: 0;

  position: sticky;
  top: ${TOOLBAR_HEIGHT_PX + FILTER_BAR_HEIGHT + COLGROUP_HEADER_HEIGHT_PX}px;
  z-index: 1;

  cursor: ${({ sortable }) => sortable ? 'pointer' : 'auto'};

  @media (${MEDIA_QUERIES_MOBILE}) {
    top: ${TOOLBAR_MOBILE_VIEW_HEIGHT_PX + FILTER_BAR_HEIGHT + COLGROUP_HEADER_HEIGHT_PX}px;
  }

  .header-container {
    height: ${COL_HEADER_HEIGHT_PX}px;
    max-width: ${({ maxWidth }) => maxWidth ? maxWidth + 'px' : 'auto'};
    min-width: ${({ minWidth }) => minWidth ? minWidth + 'px' : 'auto'};
    background: ${({ predictionVisible }) => predictionVisible ? '#eff3ff' : 'white'};
    box-sizing: border-box;
    text-align: left;
    padding: 0 6px;
  }

  .column-title {
    font-size: 14px;
    font-weight: normal;
    line-height: ${COL_HEADER_HEIGHT_PX / 2}px;
  }
`;
