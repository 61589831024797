import { createCell } from './cell';

export const createRow = (table, id, data, leafColumns, { children } = {}) => {
  const row = {
    _valuesCache: new Map(),
    id,
    data,
    children,
    isSuggestion: data.isSuggestion ?? false,
    getValue: columnId => {
      if(row._valuesCache.has(columnId))
        return row._valuesCache.get(columnId);

      const column = table.getColumn(columnId);

      if(!column?.accessor)
        return undefined;

      const val = column.accessor(row.data);

      row._valuesCache.set(columnId, val);

      return val;
    },
    getCells: () => {
      return leafColumns.map(column => createCell(table, column, row));
    }
  };

  return row;
};
