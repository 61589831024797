import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import toNumber from 'lodash/toNumber';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

import { PARAMETER_VALUE_TYPES } from '../../constants';

const isEmptyField = value => isNil(value) || value === '';

const isEmptyValues = value => typeof value !== 'number' && !value || isArray(value) && !value.length;

const mapStateToInputParameters = i => {
  let value;

  switch (i.type) {
    case PARAMETER_VALUE_TYPES.QUANTITY:
    case PARAMETER_VALUE_TYPES.CALCULATION:
    case PARAMETER_VALUE_TYPES.PREDICTION: {
      value = mapValues(
        omitBy({
          quantityMin: i.value.min,
          quantityMax: i.value.max
        }, isEmptyField),
        toNumber
      );
      break;
    }
    case PARAMETER_VALUE_TYPES.BOOLEAN: {
      value = {
        boolean: i.value,
      };
      break;
    }
    case PARAMETER_VALUE_TYPES.LINK: {
      value = {
        linkItemIds: i.value
      };
      break;
    }
    case PARAMETER_VALUE_TYPES.TEXT: {
      value = {
        textValues: i.value
      };
      break;
    }
    case PARAMETER_VALUE_TYPES.ENUM: {
      value = {
        enumValues: i.value
      };
      break;
    }
    default:
      value = {
        textValues: i.value
      };
  }

  value = omitBy(value, isEmptyValues);
  value.excludeMissingValueFlag = isEmpty(value);

  return {
    featureId: i.featureId,
    include: i.method === 'INCLUDE',
    ...value
  };
};

export default mapStateToInputParameters;
