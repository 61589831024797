import React, { useCallback, useMemo, useState } from 'react';
import PT from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import FiltersToolbar from './FiltersToolbar';
import BatchActionsContainer from './BatchActionsContainer';
import PrimaryToolbarActions from './PrimaryToolbarActions';
import SecondaryToolbarActions from './SecondaryToolbarActions';

import { TABLE_VIEW } from '../constants';

import {
  ToolbarContainer,
  ToolbarHeaderActionsContainer,
} from './styles';
import { COLOR_WHITE, MEDIA_QUERIES_MOBILE } from '../../../styles';
import { hasAdminAccess, hasWriteAccess } from '../../../utils/roles';

const getPath = (dataById, folderId, path = '') => {
  if(!folderId)
    return path;

  const folder = dataById[folderId];

  if(!folder) return path;

  path = folder.title + ' / ' + path;

  return getPath(dataById, folder.parentFolderId, path);
};

const Toolbar = ({
  totalItems,
  selectedIds,
  initialView,
  queryId,
  displayView,
  insightsData,
  dashboardRef,
  dashboardData,
  disableViewTools,
  displaySelected,
  tablesById,
  onSearch,
  onOpenSelectParams,
  onToggleDisplaySelected,
  onDelete,
  onClear,
  onSelectDisplayView,
  savedViewsProps,
  queryVariables,
  filtersData,
  activeFiltersData,
  onFiltersSubmit,
  filtersDisabled,
  onSingleFilterUpdate,
  removeFilters,
  disableParamsFilterSync,
  handleToggleSidebar,
  suggestionsProps
}) => {
  const [isSearchActive, setIsSearchActive] = useState(false);

  const isMobile = useMediaQuery(`(${MEDIA_QUERIES_MOBILE})`);

  const handleSearch = useCallback((value) => {
    setIsSearchActive(!!value);
    onSearch(value);
  }, [onSearch]);

  const disableParametersTool = useMemo(() => {
    return (disableViewTools && !isSearchActive) || displayView !== TABLE_VIEW;
  }, [disableViewTools, isSearchActive, displayView]);

  const selectedTables = useMemo(() => {
    const selectedTableIds = activeFiltersData.tableIds ?? [];

    if(!selectedTableIds.length)
      return [];

    const tablesById = {};

    for(const table of filtersData?.tables ?? []) {
      tablesById[table.id] = table;
    }

    const result = [];

    for(const tableId of selectedTableIds) {
      const table = tablesById[tableId];

      if(!table) continue;

      const { id, title, parentFolderId, viewerMaxRole, isTableAi } = table;

      result.push({
        id,
        title,
        viewerMaxRole,
        path: getPath(tablesById, parentFolderId),
        isTableAi
      });
    }

    return result;
  }, [activeFiltersData, filtersData]);

  const isTableAi = useMemo(() => {
    return selectedTables.length === 1 && selectedTables[0].isTableAi;
  }, [selectedTables]);

  const disableAiSuggestions = useMemo(() => {
    return selectedTables.length !== 1 ||
      (!isTableAi && !hasAdminAccess(selectedTables[0].viewerMaxRole)) ||
      (isTableAi && !hasWriteAccess(selectedTables[0].viewerMaxRole)) ||
      suggestionsProps.loading;
  }, [isTableAi, selectedTables, suggestionsProps.loading]);

  const isDashboardsDisabled = isSearchActive || Boolean(selectedIds.length);

  return (
    <Box
      sx={{
        px: '24px',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 3,
        backgroundColor: COLOR_WHITE,
      }}
    >
      <ToolbarContainer>
        {(!disableViewTools || isSearchActive)
          ? (
            <BatchActionsContainer
              minimize={isMobile}
              onClear={onClear}
              totalItems={totalItems}
              selectedTables={selectedTables}
              selectedIds={selectedIds}
              displaySelected={displaySelected}
              onToggleDisplaySelected={onToggleDisplaySelected}
              onDelete={onDelete}
            />
          )
          : null
        }

        <ToolbarHeaderActionsContainer>
          <PrimaryToolbarActions
            selectedIds={selectedIds}
            displayView={displayView}
            insightsData={insightsData}
            initialView={initialView}
            dashboardRef={dashboardRef}
            dashboardData={dashboardData}
            queryId={queryId}
            queryInput={queryVariables.input}
            disableViewTools={disableViewTools}
            savedViewsProps={savedViewsProps}
          />

          <SecondaryToolbarActions
            onSearch={handleSearch}
            displayView={displayView}
            onOpenSelectParams={onOpenSelectParams}
            disableViewTools={disableViewTools}
            disableParametersTool={disableParametersTool}
            queryId={queryId}
            dashboardRef={dashboardRef}
            dashboardData={dashboardData}
            tablesById={tablesById}
            queryVariables={queryVariables}
            handleToggleSidebar={handleToggleSidebar}
          />
        </ToolbarHeaderActionsContainer>
      </ToolbarContainer>

      <FiltersToolbar
        onFiltersSubmit={onFiltersSubmit}
        activeFiltersData={activeFiltersData}
        filtersData={filtersData}
        filtersDisabled={filtersDisabled}
        onSingleFilterUpdate={onSingleFilterUpdate}
        removeFilters={removeFilters}
        disableParamsFilterSync={disableParamsFilterSync}
        isDashboardsDisabled={isDashboardsDisabled}
        isSearchActive={isSearchActive}
        onSelectDisplayView={onSelectDisplayView}
        displayView={displayView}
        insightsData={insightsData}
        activeTableId={selectedTables[0]?.id}
        disableAiSuggestions={disableAiSuggestions}
        isTableAi={isTableAi}
        suggestionsProps={suggestionsProps}
      />
    </Box>
  );
};

Toolbar.propTypes = {
  loading: PT.bool,
  selectedIds: PT.arrayOf(PT.string),
  totalItems: PT.number,
  initialView: PT.object,
  queryId: PT.string,
  onSearch: PT.func,
  onOpenSelectParams: PT.func,
  onToggleDisplaySelected: PT.func,
  onShare: PT.func,
  onDelete: PT.func,
  displayView: PT.string,
  insightsData: PT.object,
  disableViewTools: PT.bool,
  dashboardRef: PT.object,
  dashboardData: PT.object,
  displaySelected: PT.bool,
  onClear: PT.func,
  tablesById: PT.object,
  onSelectDisplayView: PT.func,
  filtersData: PT.object,
  activeFiltersData: PT.object,
  onFiltersSubmit: PT.func,
  queryVariables: PT.object,
  filtersDisabled: PT.bool,
  onSingleFilterUpdate: PT.func.isRequired,
  removeFilters: PT.bool,
  disableParamsFilterSync: PT.bool,
  savedViewsProps: PT.shape({
    onSelect: PT.func.isRequired,
    onSave: PT.func.isRequired,
    onRename: PT.func.isRequired,
    onUpdate: PT.func.isRequired,
    onDiscard: PT.func.isRequired,
    onDelete: PT.func.isRequired,
    selectedView: PT.shape({
      id: PT.string.isRequired,
      title: PT.string.isRequired,
      dashboardId: PT.string,
      dashboardStateId: PT.string,
      tableItemQueryId: PT.string.isRequired,
      changed: PT.bool.isRequired,
    }),
  }),
  handleToggleSidebar: PT.func.isRequired,
  suggestionsProps: PT.object.isRequired,
};

export default Toolbar;
