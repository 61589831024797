import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { InfoContainer, InfoContainerHeader, InfoBoldText } from './styles';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const SettingsDialog = ({ open, data, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        '.MuiDialog-paper': {
          width: '520px'
        }
      }}
    >
      <DialogTitle>
        Prediction settings
      </DialogTitle>

      <DialogContent>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          mb: '18px',
          '& > :nth-child(2), & > :nth-child(3)': {
            maxWidth: 'calc(50% - 5px)'
          },
        }}
        >
          <InfoContainer>
            <InfoContainerHeader>
              Target Parameter
            </InfoContainerHeader>

            <Tooltip title={data?.title}>
              <InfoBoldText>
                {data?.title}
              </InfoBoldText>
            </Tooltip>
          </InfoContainer>

          <InfoContainer>
            <InfoContainerHeader>
              Model Name
            </InfoContainerHeader>

            <Tooltip title={data?.model.title}>
              <InfoBoldText noWrap>
                {data?.model.title}
              </InfoBoldText>
            </Tooltip>
          </InfoContainer>

          <InfoContainer>
            <InfoContainerHeader>
              Accuracy (RMSE)
            </InfoContainerHeader>

            <InfoBoldText noWrap>
              {data?.model.latestVersion.score}
            </InfoBoldText>
          </InfoContainer>
        </Box>

        <Box>
          <InfoBoldText>Input Parameters</InfoBoldText>

          <List sx={{ listStyleType: 'disc', padding: '0 0 0 20px' }}>
            {data?.model.latestVersion.inputParameters.map((parameter) => (
              <ListItem
                key={parameter.id}
                component="li"
                sx={{ display: 'list-item', padding: 0 }}
              >
                <ListItemText sx={{ fontSize: '14px' }}>
                  {parameter.title}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SettingsDialog.propTypes = {
  onClose: PT.func.isRequired,
  open: PT.bool.isRequired,
  data: PT.object,
};

export default SettingsDialog;
