import React from 'react';
import PropTypes from 'prop-types';

import SuggestionsLoadingRow from './SuggestionsLoadingRow';
import SuggestedExperimentsHeader from './SuggestedExperimentsHeader';

const SuggestedExperiments = ({ suggestionsProps, length, disableActions }) => {
  return (
    <>
      {suggestionsProps.loading &&
        <SuggestionsLoadingRow length={length} />
      }

      {suggestionsProps.data.length ?
        <SuggestedExperimentsHeader
          onSuggestionsCreate={suggestionsProps.onSuggestionsCreate}
          onReset={suggestionsProps.onReset}
          length={length}
          disableActions={disableActions}
        />
        : null
      }
    </>
  );
};

SuggestedExperiments.propTypes = {
  suggestionsProps: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  disableActions: PropTypes.bool.isRequired
};

export default SuggestedExperiments;
