import { useReducer, useMemo, useCallback } from 'react';
import without from 'lodash/without';
import omit from 'lodash/omit';
import Big from 'big.js';

const createInitialState = () => {
  const ingredients = {
    ids: [],
    byId: {}
  };

  return ingredients;
};

const actions = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  CANCEL: 'CANCEL',
  CHANGE_TITLE: 'CHANGE_TITLE',
  CHANGE_VALUE: 'CHANGE_VALUE',
  SUBMIT: 'SUBMIT',
};

const addReducer = (state) => {
  const id = window.crypto.randomUUID();

  return {
    ...state,
    ids: state.ids.concat(id),
    byId: {
      ...state.byId,
      [id]: {
        item: null,
        quantity: null
      }
    }
  };
};

const editReducer = (state, { payload }) => {
  return {
    ...state,
    ids: state.ids.concat(payload.ingredient.id),
    byId: {
      ...state.byId,
      [payload.ingredient.id]: {
        ...payload.ingredient
      }
    }
  };
};

const cancelReducer = (state, { payload }) => {
  return {
    ...state,
    ids: without(state.ids, payload.id),
    byId: omit(state.byId, payload.id),
  };
};

const changeTitleReducer = (state, { payload }) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: {
        ...state.byId[payload.id],
        item: payload.value
      }
    }
  };
};

const changeValueReducer = (state, { payload }) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: {
        ...state.byId[payload.id],
        quantity: payload.value
      }
    }
  };
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.ADD:
      return addReducer(state);

    case actions.EDIT:
      return editReducer(state, action);

    case actions.CANCEL:
    case actions.SUBMIT:
      return cancelReducer(state, action);

    case actions.CHANGE_TITLE:
      return changeTitleReducer(state, action);

    case actions.CHANGE_VALUE:
      return changeValueReducer(state, action);

    default:
      return state;
  }
};

const useIngredients = (data) => {
  const [state, dispatch] = useReducer(reducer, null, createInitialState);

  const ingredients = useMemo(() => {
    const result = [];
    const ids = new Set();

    for(const { id, item, quantity } of data.filteredFeatures) {
      if(state.byId[id]) {
        result.push({ ... state.byId[id], key: id, edit: true });

        ids.add(id);
      } else {
        result.push({ id, item, quantity, key: id });
      }
    }

    for(const id of state.ids) {
      if(!ids.has(id))
        result.push({ ...state.byId[id], key: id, edit: true });
    }

    return result;
  }, [data.filteredFeatures, state]);

  const total = useMemo(() => {
    return ingredients.reduce((acc, curr) => acc.plus(curr.quantity ?? 0), Big(0));
  }, [ingredients]);

  const usedItemIds = useMemo(() => {
    const result = new Set();

    for(const { item } of ingredients) {
      if(item)
        result.add(item.id);
    }

    return result;
  }, [ingredients]);

  const allIngredientsByItemId = useMemo(() => {
    const result = {};

    for(const { id, item, quantity } of data.features) {
      result[item.id] = { id, key: id, item, quantity };
    }

    return result;
  }, [data.features]);

  const onAdd = useCallback(() => {
    dispatch({ type: actions.ADD });
  }, []);

  const onEdit = useCallback((id) => {
    const ingredient = data.filteredFeatures.find(param => param.id === id);

    dispatch({ type: actions.EDIT, payload: { ingredient } });
  }, [data.filteredFeatures]);

  const onCancel = useCallback((id) => {
    dispatch({ type: actions.CANCEL, payload: { id } });
  }, []);

  const onChangeTitle = useCallback((id, value) => {
    dispatch({ type: actions.CHANGE_TITLE, payload: { id, value } });
  }, []);

  const onChangeValue = useCallback((id, value) => {
    dispatch({ type: actions.CHANGE_VALUE, payload: { id, value } });
  }, []);

  const onSubmit = useCallback((id) => {
    dispatch({ type: actions.SUBMIT, payload: { id } });
  }, []);

  return [
    ingredients,
    {
      total,
      usedItemIds,
      allIngredientsByItemId,
      onAdd,
      onEdit,
      onCancel,
      onChangeTitle,
      onChangeValue,
      onSubmit
    }
  ];
};

export default useIngredients;

