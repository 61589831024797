import { useReducer, useCallback } from 'react';

const initialState = {
  byId: {}
};

const actions = {
  TOGGLE_PREDICTION_VISIBILITY: 'TOGGLE_PREDICTION_VISIBILITY'
};

const defaultVisibility = true;

const reducer = (state, action) => {
  switch(action.type) {
    case actions.TOGGLE_PREDICTION_VISIBILITY:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            visible: !(state.byId[action.payload.id]?.visible ?? defaultVisibility) // initial value is `true`
          }
        }
      };

    default:
      return state;
  }
};

export default function usePredictiveColumns() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const isPredictionVisible = useCallback((columnId) => {
    return state.byId[columnId]?.visible ?? defaultVisibility;
  }, [state]);

  const togglePredictionVisibility = useCallback((columnId) => {
    dispatch({
      type: actions.TOGGLE_PREDICTION_VISIBILITY,
      payload: { id: columnId }
    });
  }, []);

  return {
    isPredictionVisible,
    togglePredictionVisibility
  };
}
