import React, { useCallback } from 'react';
import PT from 'prop-types';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';

import Input from '../../../../../../components/Input';
import Textarea from '../../../../../../components/Textarea';
import Content from './Content';

import useTableFormulationFormHook from './hooks/table-formulation-form.hook';

const FormulationForm = ({
  initialData,
  readOnly,
  loading,
  error,
  onCancel,
  onSubmit,
  onDataChanged
}) => {
  const {
    state,
    setTitleValue,
    setDescriptionValue,
    setTitleError,
    setTablesError,
    setTables,
    removeTable,
    setUnit,
    resetState
  } = useTableFormulationFormHook(initialData, onDataChanged);

  const handleCancel = useCallback(() => {
    onCancel();
    resetState();
  }, [onCancel, resetState]);

  const handleSave = useCallback(() => {
    const title = state.title.value.trim();

    if(!title) {
      setTitleError('The "Title" field is required');
      return;
    }

    if(!state.tables.value.length) {
      setTablesError('The "Tables" field is required');
      return;
    }

    const newValue = {
      title,
      titleTableIds: state.tables.value.map(({ id }) => id),
      unitId: state.unit.value?.id || null,
      description: state.description.value || null
    };

    onSubmit(newValue);
    onDataChanged?.(false);
  }, [
    onSubmit,
    setTablesError,
    setTitleError,
    state.description.value,
    state.tables.value,
    state.title.value,
    state.unit.value?.id,
    onDataChanged
  ]);

  return (
    <Box
      padding="16px 32px"
      maxWidth="800px"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Input
        label="Title"
        disabled={readOnly}
        value={state.title.value}
        error={state.title.error}
        onChange={setTitleValue}
      />

      <Textarea
        label="Description"
        value={state.description.value}
        disabled={readOnly}
        onChange={setDescriptionValue}
      />

      <Content
        readOnly={readOnly}
        selectedTables={state.tables.value}
        selectedUnit={state.unit.value}
        onUnitChange={setUnit}
        onTablesChange={setTables}
        onTableRemove={removeTable}
        tablesError={state.tables.error}
      />

      {error ?
        <FormHelperText sx={{ ml: 0 }} error>
          {error.message}
        </FormHelperText> :
        null
      }

      <Divider />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap="12px"
      >
        {readOnly ?
          <Button
            variant="contained"
            onClick={handleCancel}
          >
            Close detailed view
          </Button> :
          <>
            <Button
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              loading={loading}
            >
              Save formulation
            </LoadingButton>
          </>
        }
      </Box>
    </Box>
  );
};

FormulationForm.propTypes = {
  initialData: PT.object,
  readOnly: PT.bool,
  loading: PT.bool,
  error: PT.object,
  onCancel: PT.func,
  onSubmit: PT.func,
  onDataChanged: PT.func
};

export default FormulationForm;
