import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import { PARAMETER_VALUE_TYPES } from '../constants';
import { PreventClosingContext } from '../../FiltersPopper/PreventClosingContext';

const useStyles = makeStyles({
  chipsInput: {
    '&.MuiTextField-root': {
      minHeight: 32,

      '& .MuiInputBase-root': {
        paddingRight: '42px',
      }
    },

    '&.MuiTextField-root .MuiInputBase-root': {
      flexGrow: 1,
      padding: '2px',

      '& .MuiSvgIcon-root': {
        width: '18px',
        height: '18px'
      },

      '& .MuiInputBase-input': {
        padding: 0,
        paddingLeft: 7
      },

      '& .MuiChip-root': {
        marginLeft: '4px',
        marginTop: '2px',
        marginBottom: '2px',
        borderRadius: '5px',
      }
    },
  },
  chip: {
    height: '22px',

    '& .MuiChip-label': {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
  },
  chipIcon: {
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '12px'
    }
  }
});

const ChipsInput = ({ value, onChange, parameter, type }) => {
  const { setPreventClosing } = useContext(PreventClosingContext);

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = useCallback((e, reason) => {
    if (['blur', 'escape'].includes(reason) || reason === 'toggleInput' && e.target instanceof SVGElement){
      setPreventClosing();
      setOpen(false);
      setPreventClosing(true);
      setTimeout(() => setPreventClosing(false), 300);
    }
  }, [setPreventClosing]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleChange = useCallback((event, newValue) => {
    onChange(newValue.map(i => {
      if (type === PARAMETER_VALUE_TYPES.LINK)
        return i.value;

      return i.label;
    }));
  }, [onChange, type]);

  const parameterValuesKey = (data) => {
    return data.linkValues.map(i => ({ label: i.title, value: i.id }));
  };

  const values = parameterValuesKey(parameter);

  const selectedValues = useMemo(() => {
    return value.map(i => {
      if(type === PARAMETER_VALUE_TYPES.LINK) {
        const { id, title } = parameter.linkValues.find(({ id }) => id === i);

        return { value: id, label: title };
      }

      return { label: i };
    }).filter(i => map(values, 'label').includes(i.label));
  }, [value, type, parameter.linkValues, values]);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      limitTags={1}
      clearIcon={null}
      isOptionEqualToValue={(a, b) => {
        if (type === PARAMETER_VALUE_TYPES.LINK)
          return a?.value === b?.value;

        return a.label === b.label;
      }}
      options={(values ?? [])}
      onChange={handleChange}
      value={selectedValues}
      renderTags={(tagValue, getTagProps, ownerState) => {
        if (ownerState.focused)
          return null;

        if (tagValue.length === 1)
          return (
            <Typography
              sx={{
                paddingLeft: '4px'
              }}
              noWrap
            >
              {tagValue[0].label}
            </Typography>
          );

        return (
          <Typography
            sx={{
              paddingLeft: '4px'
            }}
          >
            {`${tagValue.length} selected`}
          </Typography>
        );
      }}
      renderInput={(params) => {
        let secondaryStyles = null;

        if (!open && value.length)
          secondaryStyles = {
            '&.MuiTextField-root .MuiInputBase-root': {
              '& .MuiInputBase-input': {
                height: 0
              },
            }
          };

        return (
          <TextField
            {...params}
            className={classes.chipsInput}
            sx={secondaryStyles}
          />
        );
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{ paddingLeft: '4px' }}
        >
          <Checkbox
            style={{ marginRight: 4 }}
            checked={selected}
          />

          <Tooltip title={option.label}>
            <Typography
              noWrap
            >
              {option.label}
            </Typography>
          </Tooltip>
        </li>
      )}
    />
  );
};

ChipsInput.propTypes = {
  type: PropTypes.oneOf([
    PARAMETER_VALUE_TYPES.BOOLEAN,
    PARAMETER_VALUE_TYPES.TEXT,
    PARAMETER_VALUE_TYPES.LINK,
  ]).isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  parameter: PropTypes.shape({
    textValues: PropTypes.array,
    linkValues: PropTypes.array
  })
};

export default ChipsInput;
