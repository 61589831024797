import React, { memo, useState, useCallback, useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PT from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Link from '@mui/material/Link';

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

import PanelResizeControl from '../../../PanelResizeControl';
import AIFeatureNormal from '../../../Icons/AIFeature/AIFeatureNormal';
import TableCell, { CELL_PADDING } from '../TableCell';
import ActionsMenu from './ActionsMenu';

import searchSplit from '../../utils/searchSplit';
import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';

import {
  CONTAINER_BORDER_WIDTH_PX,
  ROW_HEIGHT_PX
} from '../../constants';
import { sidebarSources } from '../../../../analytics/constants';
import { TITLE_PANEL_WIDTH } from '../../../PanelResizeControl/constants';

import { COLOR_BLACK } from '../../../../styles';

const label = {
  inputProps: {
    'aria-label': 'Select item'
  }
};

const UPDATE_ITEM = gql`
  mutation UpdateTableItem($id: ID!, $data: UpdateTableItemInput!) {
   updateTableItem(id: $id, data: $data) {
    id
    title
   }
  }
`;

const ItemName = ({
  className,
  item,
  checked,
  onCheckChange,
  onDelete,
  onClick,
  search,
  onUpdateTitleWidth,
  titleWidth,
  suggestions,
  onSuggestionApprove
}) => {
  const [updateItem] = useMutation(UPDATE_ITEM);

  const dispatch = useDispatch();

  const { id, title } = item;
  const [anchorEl, setAnchorEl] = useState(null);
  const [edit, setEdit] = useState(null);
  const [editedTitle, setEditedTitle] = useState(title);

  const handleCheckChange = useCallback((ev) => {
    onCheckChange(id, ev.target.checked);
  }, [id, onCheckChange]);

  const handleMenuOpen = useCallback((ev) => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(() => {
    onClick({ id: item.id, source: sidebarSources.TABLE_ITEM_NAME });
  }, [item.id, onClick]);

  const toggleEdit = useCallback(() => setEdit(state => !state), [setEdit]);

  const handleChange = useCallback((e) => setEditedTitle(e.target.value), [setEditedTitle]);

  const handleSubmit = useCallback(async () => {
    toggleEdit();

    try {
      await updateItem({
        variables: {
          id,
          data: {
            title: editedTitle
          }
        },
        optimisticResponse: {
          updateTableItem: {
            __typename: 'TableItem',
            id,
            title: editedTitle
          }
        }
      });
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [dispatch, editedTitle, id, toggleEdit, updateItem]);

  const handleKeyDown = useCallback(e => {
    if (['Enter', 'Tab'].includes(e.key)) {
      e.stopPropagation();
      e.preventDefault();

      handleSubmit();
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();

      setEditedTitle(title);
      toggleEdit();
    }
  }, [handleSubmit, title, toggleEdit]);

  const handleSubmitSuggestion = useCallback(() => onSuggestionApprove(id), [id, onSuggestionApprove]);

  const searchedTitle = useMemo(() => {
    if (!search || suggestions)
      return (
        <Typography
          variant="body2"
          noWrap
        >
          {title}
        </Typography>
      );

    const chunks = searchSplit(title, search);

    return (
      <>
        <Typography
          variant="body2"
          display="inline"
          noWrap
        >
          {chunks.map((chunk) => {
            if (chunk.search){
              return (
                <mark key={chunk.id}>
                  {chunk.text}
                </mark>
              );
            }

            return (
              <React.Fragment key={chunk.id}>
                {chunk.text}
              </React.Fragment>
            );
          })}
        </Typography>
      </>
    );
  }, [suggestions, search, title]);

  return (
    <TableCell role="rowheader" className={className} suggestions={suggestions}>
      <div
        className="item-name-container"
        style={{
          width: `${titleWidth + (suggestions ? CELL_PADDING : 0)}px`,
          padding: `0 ${suggestions ? CELL_PADDING : 0}px`
        }}
      >
        {suggestions ?
          null
          :
          <Checkbox
            {...label}
            disableRipple
            sx={{
              '&.MuiCheckbox-root': {
                py: 0,
                pl: 0,
                pr: '6px'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 16
              }
            }}
            checked={checked}
            onChange={handleCheckChange}
          />
        }

        {!edit ?
          <>
            <Typography
              className={suggestions ? 'item-name-text-disabled' : 'item-name-text'}
              variant="body2"
              color="rgba(0, 0, 0, 0.87)"
              noWrap
              title={title}
            >
              <Link
                onClick={!suggestions ? handleClick : null}
              >
                {searchedTitle}
              </Link>
            </Typography>

            {suggestions ?
              null
              :
              <IconButton
                disableRipple
                sx={{
                  '&.MuiButtonBase-root': {
                    visibility: 'hidden',
                    p: 0,
                    pl: '6px',
                    height: `18px`,
                    width: `18px`
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 18
                  }
                }}
                onClick={toggleEdit}
              >
                <EditIcon />
              </IconButton>
            }
          </>
          :
          <ClickAwayListener onClickAway={handleSubmit}>
            <OutlinedInput
              autoFocus
              value={editedTitle}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              sx={{
                flex: '1 1 auto',
                fontSize: 14,
                '.MuiInputBase-input': {
                  padding: '4px',
                }
              }}
              endAdornment={
                <CloseIcon
                  onClick={toggleEdit}
                  size="small"
                  sx={{
                    color: '#8C8C8C',
                    fontSize: 18,
                    cursor: 'pointer'
                  }}
                />
              }
            />
          </ClickAwayListener>
        }

        {suggestions ?
          <ButtonBase
            sx={{
              color: 'white',
              background: 'linear-gradient(221deg, #54B9D3 28.6%, #3263F6 84.74%)',
              borderRadius: '4px',
              ml: '4px',
              p: '2px 4px',
              gap: '2px',
              height: '20px',
              visibility: 'hidden',
            }}
            onClick={handleSubmitSuggestion}
            className="add-suggestion-button"
          >
            <AIFeatureNormal
              sx={{ fontSize: '12px' }}
            />

            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
              }}
            >
              Add
            </Typography>
          </ButtonBase>
          :
          <IconButton
            aria-label="more"
            aria-controls={open ? 'item-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            disableRipple
            sx={{
              '&.MuiButtonBase-root': {
                p: 0,
                pl: '6px',
                height: `18px`,
                width: `18px`
              },
              '& .MuiSvgIcon-root': {
                fontSize: 18
              }
            }}
          >
            <MoreVertIcon />
          </IconButton>
        }

        <ActionsMenu
          item={item}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          onDelete={onDelete}
        />
      </div>

      <PanelResizeControl
        panelWidth={titleWidth + CELL_PADDING}
        onResize={onUpdateTitleWidth}
        itemType={TITLE_PANEL_WIDTH}
      />
    </TableCell>
  );
};

ItemName.propTypes = {
  className: PT.string,
  item: PT.object,
  checked: PT.bool,
  onCheckChange: PT.func,
  onDelete: PT.func,
  onClick: PT.func,
  search: PT.string,
  onUpdateTitleWidth: PT.func,
  titleWidth: PT.number,
  suggestions: PT.object,
  onSuggestionApprove: PT.func
};

export default styled(memo(ItemName))`
  position: sticky;
  left: ${CONTAINER_BORDER_WIDTH_PX}px;
  z-index: 1;
  background-color: white;
  background-clip: padding-box;

  .item-name-container {
    height: ${ROW_HEIGHT_PX}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .item-name-clickable {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .item-name-text, .item-name-text-disabled {
    flex: 1 1 auto;
    
    a {
      color: ${COLOR_BLACK};
      text-decoration: none;
    }
  }
    
  &:hover {
    .MuiButtonBase-root {
      visibility: initial;
    }
  }
`;
