import React from 'react';
import PT from 'prop-types';

import QuantityValue from './QuantityValue';

const QuantityValueDataWrapper = ({ data }) => (
  <QuantityValue
    value={data.quantity}
    unit={data.unit}
    prediction={data.prediction}
    uncertaintyUp={data.uncertaintyUp}
    uncertaintyDown={data.uncertaintyDown}
  />
);

QuantityValueDataWrapper.propTypes = {
  data: PT.shape({
    quantity: PT.number,
    prediction: PT.number,
    uncertaintyUp: PT.number,
    uncertaintyDown: PT.number,
    error: PT.string,
    unit: PT.shape({
      name: PT.string
    })
  })
};

export default QuantityValueDataWrapper;

