export * from '../../constants';

export const IAM_ROLES = new Map([
  ['READ', 'READ'],
  ['WRITE', 'WRITE'],
  ['ADMIN', 'ADMIN']
]);

export const VALUE_TYPE = {
  QUANTITY: 'QUANTITY',
  BOOLEAN: 'BOOLEAN',
  TEXT: 'TEXT',
  LINK: 'LINK',
  CALCULATION: 'CALCULATION',
  ENUM: 'ENUM',
  PREDICTION: 'PREDICTION'
};

export const SORT_SETTINGS = 'SORT_SETTINGS';
export const TRASH_SORT_SETTINGS = 'ARCHIVE_SORT_SETTINGS';
