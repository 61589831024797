import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import AIFeatureNormal from '../../../../../components/Icons/AIFeature/AIFeatureNormal';
import SuggestionsWarningDialog from '../../../Toolbar/AiSuggestionsButton/SuggestionsWarningDialog';

import { SUGGESTIONS } from '../../../../../analytics/constants';

const SuggestedExperimentsHeader = ({ onSuggestionsCreate, onReset, length, disableActions }) => {
  const { track } = useAnalytics();

  const [open, setOpen] = useState(false);

  const handleToggleDialog = useCallback(() => setOpen(state => !state), []);

  const handleCreate = useCallback(() => {
    onSuggestionsCreate();
    track(SUGGESTIONS.tryAgain);
  }, [onSuggestionsCreate, track]);

  return (
    <>
      <tr style={{ position: 'relative' }}>
        <td colSpan={length + 1} style={{ padding: '0px' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            border: '3px solid #2196F3',
            borderBottom: 'none',
            height: '36px',
            pl: '12px',
            background: 'linear-gradient(270deg, #54B9D3 46.79%, #2196F3 102.88%)',
            color: 'white'
          }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                position: 'sticky',
                left: '36px'
              }}
            >
              <AIFeatureNormal
                sx={{ fontSize: '14px' }}
              />

              <Typography fontSize={14}>
                AI Suggested Experiments
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginRight: '8px',
              }}
            >
              <Tooltip title={!disableActions ? 'Delete all and regenerate suggestions' : ''}>
                <RefreshIcon
                  sx={{
                    fontSize: '14px',
                    color: disableActions && '#b2ebf2'
                  }}
                  onClick={!disableActions ? handleToggleDialog : null}
                />
              </Tooltip>

              <Divider
                orientation="vertical"
                sx={{ height: '18px', borderColor: 'white' }}
              />

              <Tooltip title={!disableActions ? 'Delete all suggestions' : null}>
                <CloseIcon
                  sx={{ fontSize: '14px', color: disableActions && '#b2ebf2' }}
                  onClick={!disableActions ? onReset : null}
                />
              </Tooltip>
            </Box>
          </Box>
        </td>
      </tr>

      <SuggestionsWarningDialog
        open={open}
        onCancel={handleToggleDialog}
        onSubmit={handleCreate}
      />
    </>
  );
};

SuggestedExperimentsHeader.propTypes = {
  onSuggestionsCreate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  disableActions: PropTypes.bool.isRequired
};

export default SuggestedExperimentsHeader;
