import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import wsLink from './ws-link';
import httpLink from './http-link';

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        viewer: {
          merge(existing = {}, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        items: {
          keyArgs() {
            return ['query', ['filters']];
          },

          /*
            Define a field policy to merge the results of paginated queries
            (for lazy-loading) into a single list.

            https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
          */

          merge(prev, next, { variables }) {
            const validNext = next ?? { pageInfo: {}, edges: [] };

            if (variables.after) { // on "loading more"
              const validPrev = prev ?? { pageInfo: {}, edges: [] };

              return {
                ...validPrev,
                pageInfo: {
                  ...validPrev.pageInfo,
                  ...validNext.pageInfo,
                },
                edges: [
                  ...validPrev.edges,
                  ...validNext.edges
                ]
              };
            }

            return validNext;
          }
        },

        item: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          }
        },

        linkedItems(_, { args, toReference }) {
          return toReference({
            __typename: 'LinkedItems',
            id: args.id,
          });
        },

        tableItems: {
          keyArgs: ['input'],
          merge(existing, incoming, { variables, readField }) {
            if(!existing || !variables.after)
              return incoming;

            const existingEdges = Array.prototype.slice.call(existing.itemsTable.edges);

            const newItems = {};

            for(const [index, edge] of existingEdges.entries()) {
              if(!edge.cursor)
                newItems[readField('id', edge.node.item)] = index;
              else
                break;
            }

            const newEdges = [];

            incoming.itemsTable.edges.forEach(edge => {
              const id = readField('id', edge.node.item);
              if(Object.hasOwn(newItems, id))
                existingEdges.splice(newItems[id], 1, edge);
              else
                newEdges.push(edge);
            });

            return {
              ...existing,
              itemsTable: {
                ...existing.itemsTable,
                edges: existingEdges.concat(newEdges),
                pageInfo: {
                  ...incoming.itemsTable.pageInfo
                }
              }
            };
          }
        },

        tableItemsList: {
          keyArgs: ['input'],
          merge(existing, incoming, { variables }) {
            if(!existing || !variables.after)
              return incoming;

            return {
              ...existing,
              edges: [
                ...existing.edges,
                ...incoming.edges
              ],
              pageInfo: {
                ...incoming.pageInfo
              }
            };
          }
        },

        tableItemComments: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    ProtocolFeature: {
      merge: true
    },
    ProtocolFeatureValueQuantity: {
      merge: true
    },
    ProtocolFeatureValueText: {
      merge: true
    },
    ProtocolFeatureValueBoolean: {
      merge: true
    },
    ProtocolFeatureValueLink: {
      merge: true
    },
    PlatformStats: {
      merge: true
    },
    TableIAM: {
      merge: true
    },
    TableValue: {
      keyFields: ['tableParameterId', 'tableItemId']
    },
  },
  possibleTypes: {
    TableValue: [
      'TableValueCalculation',
      'TableValueQuantity',
      'TableValueText',
      'TableValueBoolean',
      'TableValueLink',
      'TableValueEnum',
      'TableValuePrediction'
    ],
    TableParameter: [
      'TableParameterDefault',
      'TableParameterCalculation',
      'TableParameterEnum',
      'TableParameterPrediction'
    ]
  }
});

const client = new ApolloClient({
  connectToDevTools: process.env.REACT_APP_ENV !== 'production',
  cache,
  link: splitLink,
  defaultOptions: {
    watchQuery: {
      // https://github.com/apollographql/apollo-client/issues/6833#issuecomment-679446789
      nextFetchPolicy(lastFetchPolicy) {
        if (lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only') {
          return 'cache-first';
        }
        return lastFetchPolicy;
      }
    }
  }
});

export default client;
