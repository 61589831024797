import React from 'react';
import PT from 'prop-types';

import TableCell from '../TableCell';

const EmptyValue = ({ suggestions }) => (
  <TableCell
    suggestions={suggestions}
  >
    <div style={{ height: '40px' }} />
  </TableCell>
);

EmptyValue.propTypes = {
  suggestions: PT.object,
};

export default EmptyValue;
