import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import SavedViewIcon from '../../../../../components/SavedViews/SavedViewIcon';
import EditViewDialog from '../../../Toolbar/SavedViews/dialogs/EditViewDialog';
import DeleteViewDialog from '../../../Toolbar/SavedViews/dialogs/DeleteViewDialog';

import { ChangedLabel, InfoBlock, ListItem, MenuBlock, Title } from './styles';
import { COLOR_PRIMARY_DARK } from '../../../../../styles';
import VIDEO_LINKS from '../../../../../components/VideoHelperDialog/constants';
import SaveNewViewDialog from '../../../Toolbar/SavedViews/dialogs/SaveNewViewDialog';
import VideoHelperDialog from '../../../../../components/VideoHelperDialog';

const SingleView = ({
  data,
  selected,
  changed,
  disableSaving,

  onSelect,
  onUpdate,
  onSave,
  onRename,
  onDelete,
  onDiscard
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const [newViewDialogOpen, setNewViewDialogOpen] = useState(false);
  const [renameViewDialogOpen, setRenameViewDialogOpen] = useState(false);
  const [deleteViewDialogOpen, setDeleteViewDialogOpen] = useState(false);

  const handleOpenMenu = useCallback(ev => {
    ev.stopPropagation();
    setMenuAnchorEl(ev.target);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleOpenEditViewDialog = useCallback(() => {
    setRenameViewDialogOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const handleCloseEditViewDialog = useCallback(() => {
    setRenameViewDialogOpen(false);
  }, []);

  const handleSubmitEditViewDialog = useCallback(({ title }) => {
    onRename(data.id, { title });
    setRenameViewDialogOpen(false);
  }, [data.id, onRename]);

  const handleOpenDeleteViewDialog = useCallback(() => {
    setDeleteViewDialogOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const handleCloseDeleteViewDialog = useCallback(() => {
    setDeleteViewDialogOpen(false);
  }, []);

  const handleSubmitDeleteViewDialog = useCallback(() => {
    onDelete(data.id);
    setDeleteViewDialogOpen(false);
  }, [data.id, onDelete]);

  const handleOpenNewViewDialog = useCallback(() => {
    setNewViewDialogOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const handleCloseNewViewDialog = useCallback(() => {
    setNewViewDialogOpen(null);
  }, []);

  const handleVideoDialogToggle = useCallback(() => {
    handleCloseMenu();
    setVideoLink(state => !state ? VIDEO_LINKS.SAVED_VIEW : null);
    setMenuAnchorEl(null);
  }, [handleCloseMenu]);

  const handleSubmitNewViewDialog = useCallback((data) => {
    setNewViewDialogOpen(false);
    onSave(data);
  }, [onSave]);

  const handleSelect = useCallback(() => {
    onSelect(data);
  }, [data, onSelect]);

  const handleUpdate = useCallback(() => {
    setMenuAnchorEl(null);
    onUpdate();
  }, [onUpdate]);

  const handleDiscard = useCallback(() => {
    setMenuAnchorEl(null);
    onDiscard();
  }, [onDiscard]);

  const menuList = [
    changed ? {
      title: `Update ${data.title}`,
      onClick: handleUpdate,
    } : null,
    changed ? {
      title: 'Discard changes',
      onClick: handleDiscard,
    } : null,
    changed ? {
      title: 'Save as new view',
      onClick: handleOpenNewViewDialog,
      disabled: disableSaving
    } : null,
    {
      title: 'Rename',
      onClick: handleOpenEditViewDialog,
    },
    {
      title: 'Delete',
      onClick: handleOpenDeleteViewDialog,
    },
    {
      title: 'Learn more',
      onClick: handleVideoDialogToggle,
    }
  ].filter(Boolean);

  return (
    <Box>
      <ListItem
        onClick={handleSelect}
        selected={selected}
      >
        <InfoBlock>
          <SavedViewIcon
            dashboardId={data.dashboardId}
            selected={selected}
          />

          <Title
            noWrap
            selected={selected}
          >
            {data.title}
          </Title>
        </InfoBlock>

        <MenuBlock
          onClick={handleOpenMenu}
          changed={changed || undefined}
        >
          {changed ?
            <ChangedLabel>
              Changed
            </ChangedLabel>
            : null
          }

          <IconButton
            size="small"
            sx={{ p: '0' }}
            disableRipple
          >
            <MoreVertIcon
              sx={{
                fontSize: '14px',
                color: selected ? COLOR_PRIMARY_DARK : 'inherit'
              }}
            />
          </IconButton>
        </MenuBlock>
      </ListItem>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          dense: true
        }}
        sx={{
          '.MuiMenu-paper': {
            'maxWidth': '300px'
          }
        }}
      >
        {menuList.map(menuItem => (
          <MenuItem
            key={menuItem.title}
            onClick={menuItem.onClick}
            disabled={menuItem.disabled}
          >
            {menuItem.title}
          </MenuItem>
        ))}
      </Menu>

      <SaveNewViewDialog
        open={newViewDialogOpen}
        onClose={handleCloseNewViewDialog}
        onSubmit={handleSubmitNewViewDialog}
      />

      {renameViewDialogOpen ?
        <EditViewDialog
          open={renameViewDialogOpen}
          currentTitle={data.title}
          onClose={handleCloseEditViewDialog}
          onSubmit={handleSubmitEditViewDialog}
        />
        : null
      }

      <DeleteViewDialog
        open={deleteViewDialogOpen}
        title={data.title}
        onClose={handleCloseDeleteViewDialog}
        onSubmit={handleSubmitDeleteViewDialog}
      />

      <VideoHelperDialog
        link={videoLink}
        onClose={handleVideoDialogToggle}
      />
    </Box>
  );
};

SingleView.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  changed: PropTypes.bool.isRequired,
  disableSaving: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default SingleView;
