import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';
import AIFeatureNormal from '../../../../../components/Icons/AIFeature/AIFeatureNormal';
import Box from '@mui/material/Box';

const SuggestionsLoadingRow = ({ length }) => {
  return (
    <tr style={{ position: 'relative' }}>
      <td colSpan={length + 1} style={{ padding: '0px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: '4px',
            height: '36px',
            p: '0 8px',
            background: 'linear-gradient(270deg, #54B9D3 46.79%, #2196F3 102.88%)',
            zIndex: 1,
            color: 'white'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'sticky', left: '36px', gap: '8px' }}>

            <AIFeatureNormal sx={{ fontSize: '14px' }} />

            <Typography fontSize={14}>
              Generating AI Suggested Experiments
            </Typography>
          </Box>
        </Box>
      </td>
    </tr>
  );
};

SuggestionsLoadingRow.propTypes = {
  length: PT.number.isRequired,
};

export default SuggestionsLoadingRow;
