import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const InfoContainer = styled(Box)`
  display: flex;
  padding: 8px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 1 100%;
  border-radius: 4px;
  border: 1px solid #EEE;
`;

export const InfoContainerHeader = styled(Typography)`
  font-size: 12px; 
  font-weight: 400; 
  color: rgba(0, 0, 0, 0.60)
`;

export const InfoBoldText = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
