import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ViewsMenu from '../ViewsMenu';
import FiltersButton from './FiltersButton';

import { FILTER_BAR_HEIGHT } from '../../constants';
import AiSuggestionsButton from '../AiSuggestionsButton';

const FiltersToolbar = ({
  isSearchActive,
  isDashboardsDisabled,
  onSelectDisplayView,
  displayView,
  insightsData,
  filtersData,
  activeFiltersData,
  onFiltersSubmit,
  filtersDisabled,
  onSingleFilterUpdate,
  removeFilters,
  disableParamsFilterSync,
  activeTableId,
  disableAiSuggestions,
  isTableAi,
  suggestionsProps,
}) => {

  const disabledDashboardsText = isSearchActive
    ? `Dashboards can't be accessed while the free text search is in use.`
    : 'Dashboards can’t be accessed when there are items selected';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        height: `${FILTER_BAR_HEIGHT}px`,
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px',
          minWidth: '0',
        }}
      >
        <ViewsMenu
          onChange={onSelectDisplayView}
          displayView={displayView}
          insightsData={insightsData}
          disableDashboard={isDashboardsDisabled}
          disabledText={disabledDashboardsText}
        />

        <Divider
          orientation="vertical"
          sx={{ height: '18px' }}
        />

        <FiltersButton
          data={filtersData}
          activeFilters={activeFiltersData}
          onSubmit={onFiltersSubmit}
          disabled={filtersDisabled}
          onSingleFilterUpdate={onSingleFilterUpdate}
          removeFilters={removeFilters}
          disableParamsFilterSync={disableParamsFilterSync}
        />
      </Box>

      <AiSuggestionsButton
        isTableAi={isTableAi}
        tableId={activeTableId}
        disabled={disableAiSuggestions}
        suggestionsProps={suggestionsProps}
      />
    </Box>
  );
};

FiltersToolbar.propTypes = {
  isSearchActive: PropTypes.bool,
  isDashboardsDisabled: PropTypes.bool,
  onSelectDisplayView: PropTypes.func.isRequired,
  displayView: PropTypes.string.isRequired,
  insightsData: PropTypes.object,
  filtersData: PropTypes.object.isRequired,
  activeFiltersData: PropTypes.object.isRequired,
  onFiltersSubmit: PropTypes.func.isRequired,
  filtersDisabled: PropTypes.bool,
  onSingleFilterUpdate: PropTypes.func.isRequired,
  removeFilters: PropTypes.bool,
  disableParamsFilterSync: PropTypes.bool,
  activeTableId: PropTypes.string,
  disableAiSuggestions: PropTypes.bool.isRequired,
  isTableAi: PropTypes.bool.isRequired,
  suggestionsProps: PropTypes.object.isRequired,
};

export default FiltersToolbar;
