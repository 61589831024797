import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PreventClosingContext = createContext(null);

export const PreventClosingProvider = ({ children }) => {
  const [preventClosing, setPreventClosing] = useState(false);

  return (
    <PreventClosingContext.Provider value={{ preventClosing, setPreventClosing }}>
      {children}
    </PreventClosingContext.Provider>
  );
};

PreventClosingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

