import React, { useCallback, useMemo, useState } from 'react';
import PT from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';

import SuggestionsDialog from './SuggestionsDialog';
import AIFeatureNormal from '../../../../components/Icons/AIFeature/AIFeatureNormal';
import SuggestionsWarningDialog from './SuggestionsWarningDialog';

import { SUGGESTIONS } from '../../../../analytics/constants';

const ALLOWED_SUGGESTIONS_ENV = ['staging', 'development'].includes(process.env.REACT_APP_ENV);

const AiSuggestionsButton = ({ tableId, isTableAi, suggestionsProps, disabled }) => {
  const { track } = useAnalytics();

  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const handleToggleOpenSuggestion = useCallback(() => setOpenSuggestions(state => !state), []);
  const handleToggleOpenWarning = useCallback(() => {
    setOpenWarning(state => !state);
  }, []);

  const handleSuggestionsCreate = useCallback(() => {
    suggestionsProps.onSuggestionsCreate();
    setOpenWarning(false);

    track(SUGGESTIONS.tryAgain);
  }, [suggestionsProps, track]);

  const buttonText = useMemo(() => {
    if (suggestionsProps.loading)
      return 'Loading AI Suggestions';

    return isTableAi ? 'Generate AI Suggestions' : 'Setup AI Suggestions';
  }, [isTableAi, suggestionsProps.loading]);

  const handleClick = useCallback(() => {
    if(isTableAi) {
      if(suggestionsProps.data.length)
        return handleToggleOpenWarning();

      suggestionsProps.onSuggestionsCreate();
      track(SUGGESTIONS.generate);
    } else {
      handleToggleOpenSuggestion();
    }
  }, [handleToggleOpenSuggestion, handleToggleOpenWarning, isTableAi, suggestionsProps, track]);

  if(!ALLOWED_SUGGESTIONS_ENV && !isTableAi)
    return null;

  return (
    <>
      <ButtonBase
        sx={{
          color: 'white',
          padding: '2px 12px',
          borderRadius: '4px',
          background: 'linear-gradient(263deg, #54B9D3 -1.79%, #2196F3 89%)',
        }}
        onClick={handleClick}
        disabled={disabled}
      >
        {suggestionsProps.loading ?
          <CircularProgress
            size={14}
            sx={{ mr: '6px', color: 'white' }}
          />
          :
          <AIFeatureNormal
            sx={{ fontSize: '14px', mr: '6px', color: disabled && 'rgba(0, 0, 0, 0.26)' }}
          />
        }

        <Typography
          sx={{
            fontSize: '12px',
            minWidth: 'max-content',
          }}
        >
          {buttonText}
        </Typography>
      </ButtonBase>

      <SuggestionsDialog
        open={openSuggestions}
        onClose={handleToggleOpenSuggestion}
        tableId={tableId}
      />

      <SuggestionsWarningDialog
        onCancel={handleToggleOpenWarning}
        onSubmit={handleSuggestionsCreate}
        open={openWarning}
      />
    </>
  );
};

AiSuggestionsButton.propTypes = {
  tableId: PT.string,
  disabled: PT.bool.isRequired,
  isTableAi: PT.bool.isRequired,
  suggestionsProps: PT.object.isRequired,
};

export default AiSuggestionsButton;
