import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import TableCell from '../TableCell';
import { formatDate } from './utils';

const DateTime = ({ timestamp, suggestions }) => (
  <TableCell suggestions={suggestions}>
    <Typography variant="body2" px="6px">
      {formatDate(timestamp)}
    </Typography>
  </TableCell>
);

DateTime.propTypes = {
  timestamp: PT.number,
  suggestions: PT.object,
};

export default DateTime;

