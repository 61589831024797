import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import headerImage from '../assets/header.jpg';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@mui/lab/LoadingButton';

import CREATE_SUGGESTED_TABLE from './gql';
import { openAppSnackbarNotification } from '../../../../../services/snackbar-notifications/actions';
import { componentNames, AI_SUGGESTIONS } from '../../../../../analytics/constants';
import { SNACKBAR_TYPES } from '../../../../../components/AppSnackbar';
import { COLOR_BLACK } from '../../../../../styles';

const SuggestionsDialog = ({ tableId, open, onClose }) => {
  const [createSuggestion, { loading }] = useMutation(CREATE_SUGGESTED_TABLE);
  const [note, setNote] = useState('');

  const dispatch = useDispatch();

  const { track } = useAnalytics();

  const handleChange = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await createSuggestion({
        variables: {
          tableId,
          note
        }
      });

      dispatch(openAppSnackbarNotification({
        message: 'Requested Successfully! We’ll be in touch shortly!',
        variant: SNACKBAR_TYPES.SUCCESS
      }));

      track(AI_SUGGESTIONS.request, {
        component: componentNames.TABLE_STRUCTURE
      });
    } catch (e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: SNACKBAR_TYPES.ERROR
      }));
    }

    setNote('');
    onClose();
  }, [createSuggestion, dispatch, note, onClose, tableId, track]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          width: '450px',
        }
      }}
    >
      <Box
        component="img"
        src={headerImage}
        alt="Dialog header"
        sx={{
          objectFit: 'cover',
        }}
      />

      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '18px'
          }}
        >
          Get AI Suggestions
        </Typography>

        <Box
          sx={{
            color: 'white',
            background: 'linear-gradient(to bottom, #cf64f2, #8397db)',
            borderRadius: '2px',
            ml: '4px',
            p: '0 2px',
            height: '16px',
            fontSize: '10px',
            fontWeight: '700',
          }}
        >
          New!
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          sx={{
            mb: '20px',
            fontSize: '14px',
            color: COLOR_BLACK
          }}
        >
          Accelerate your Research and Development with AI-guided experimental suggestions!
        </DialogContentText>

        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <Typography variant="body2" color="rgba(0,0,0,.6)" mb="8px">
            Tell us what are you trying to optimise
          </Typography>

          <InputBase
            multiline
            autoFocus
            fullWidth
            value={note}
            onChange={handleChange}
            rows={3}
            sx={{
              border: '1px solid #DCDBDC',
              borderRadius: '4px',
              px: '6px',
              fontSize: '14px'
            }}
          />
        </FormControl>

      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={loading}
        >
          Send Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

SuggestionsDialog.propTypes = {
  tableId: PT.string,
  open: PT.bool.isRequired,
  onClose: PT.func.isRequired,
};

export default SuggestionsDialog;
