import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';

import Popper from '@mui/material/Popper';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import AddIcon from '@mui/icons-material/Add';

import ParametersContainer from '../ParametersFilter/ParametersContainer';

import {
  createFiltersToQuery,
  createInitialOptions,
  createInitialParameters,
  createParameter,
  toValuesByIds,
  updateParameter
} from './utils';

import { PreventClosingContext } from './PreventClosingContext';

const FiltersPopper = ({
  onClose,
  onSubmit,
  data,
  value,
  anchorEl,
  featuresById,
}) => {
  const { preventClosing } = useContext(PreventClosingContext);

  const initialParameters = createInitialParameters(value, featuresById);

  const [activeFilters, setActiveFilters] = useState(() => initialParameters);

  const syncFilters = useCallback(() => {
    setActiveFilters(() => {
      const newFilters = activeFilters.filter(filter => initialParameters
        .find(v => v.featureId && v.featureId === filter.featureId || v.filterType && v.filterType === filter.filterType)
      );

      return newFilters.length && initialParameters.length < newFilters.length ? newFilters : initialParameters;
    });
  }, [activeFilters, initialParameters]);

  useEffect(() => {
    if (!isEqual(initialParameters, activeFilters)) {
      syncFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresById, value]);

  useEffect(() => {
    if (featuresById)
      setActiveFilters(state => {
        return state.map(filter => {
          const feature = featuresById[filter.featureId];
          let value = filter.value;

          if (filter.type === 'TEXT') {
            value = intersection(filter.value, feature.textValues);
          } else if (filter.type === 'LINK') {
            value = intersection(filter.value, feature.linkValues.map(({ id }) => id));
          }

          return {
            ...filter,
            value
          };
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresById]);

  const filterOptions = useMemo(() => {
    return createInitialOptions(data);
  }, [data]);

  const valuesById = useMemo(() => toValuesByIds(data), [data]);

  const handleParameterAdd = useCallback(() => {
    setActiveFilters(s => s.concat(createParameter()));
  }, []);

  const handleRemove = useCallback(idx => {
    setActiveFilters(v => v.filter((p, pidx) => pidx !== idx));
  }, []);

  const handleParameterChange = useCallback((index, { type, value }) => {
    setActiveFilters((state) => state.map((s, i) => updateParameter(s, i, index, type, value)));
  }, [setActiveFilters]);

  const handleSubmit = useCallback(() => {
    onSubmit(createFiltersToQuery(activeFilters));
  }, [activeFilters, onSubmit]);

  const handleClose = useCallback((e) => {
    if (preventClosing) {
      return;
    }
    syncFilters();
    onClose(e);
  }, [onClose, preventClosing, syncFilters]);

  return (
      //mouseEvent fixes error with Select closing entire Popper when opening as discussed https://github.com/mui/material-ui/issues/12034
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <Paper
          sx={{
            width: '640px',
          }}
        >
          <DialogContent sx={{ paddingBottom: '14px' }}>
            <ParametersContainer
              data={activeFilters}
              protocols={filterOptions}
              onChange={handleParameterChange}
              onRemove={handleRemove}
              featuresById={valuesById}
            />

            <Button
              startIcon={<AddIcon />}
              color="neutral"
              onClick={handleParameterAdd}
              sx={{
                textTransform: 'capitalize',
                pl: 0
              }}
            >
              Add
            </Button>
          </DialogContent>

          <DialogActions sx={{ padding: '0 24px 24px' }}>
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              pt="16px"
              sx={{
                borderTop: '1px solid rgb(224, 224, 224)',
                gap: '8px'
              }}
            >
              <Button onClick={handleClose}>
                cancel
              </Button>

              <Button onClick={handleSubmit} autoFocus variant="contained">
                update
              </Button>
            </Box>
          </DialogActions>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

FiltersPopper.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  featuresById: PropTypes.object,
};

export default FiltersPopper;
