import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { Container } from './styles';

import { isCertain, parseToExponential } from '../../../../../../utils';
import { COLOR_PREDICTION_ISSUE, COLOR_PREDICTION_SUCCESS } from '../../../../../../styles';

const QuantityValue = ({ value, unit, prediction, uncertaintyUp, uncertaintyDown }) => {
  const shortenedQuantity = useMemo(() => {
    if (typeof value === 'number')
      return parseToExponential(value);

    if (prediction)
      return parseToExponential(prediction);

    return '';
  }, [prediction, value]);

  const valueContent = useMemo(() => {
    if (typeof value === 'number')
      return (
        <Tooltip
          arrow
          placement="top"
          title={value}
        >
          <Typography
            sx={{
              fontSize: '14px'
            }}
          >
            {shortenedQuantity}
          </Typography>
        </Tooltip>
      );

    if (prediction)
      return (
        <Tooltip
          arrow
          placement="top"
          title={`${uncertaintyDown} - ${uncertaintyUp}`}
        >
          <Typography
            sx={{
              fontSize: '14px',
              color: isCertain(uncertaintyUp, uncertaintyDown, value) ? COLOR_PREDICTION_SUCCESS : COLOR_PREDICTION_ISSUE
            }}
          >
            {shortenedQuantity}
          </Typography>
        </Tooltip>
      );
  }, [prediction, shortenedQuantity, uncertaintyDown, uncertaintyUp, value]);

  return (
    <Container>
      {valueContent}

      {typeof value === 'number' || typeof prediction === 'number' ?
        <Typography
          sx={{
            fontSize: '14px'
          }}
        >
          {unit?.name}
        </Typography> :
        null
      }
    </Container>
  );
};

QuantityValue.propTypes = {
  value: PropTypes.number,
  prediction: PropTypes.number,
  error: PropTypes.string,
  uncertaintyUp: PropTypes.number,
  uncertaintyDown: PropTypes.number,
  unit: PropTypes.shape({
    name: PropTypes.string
  })
};

export default QuantityValue;
