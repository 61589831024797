import React, { forwardRef } from 'react';
import PT from 'prop-types';

import AIFeatureNormal from './AIFeatureNormal';
import AIFeatureActive from './AIFeatureActive';
import AIFeatureDisabled from './AIFeatureDisabled';

const ICON_TYPE = {
  NORMAL: 'normal',
  ACTIVE: 'active',
  DISABLED: 'disabled'
};

const AIFeature = forwardRef(({ type, ...props }, ref) => {
  switch(type) {
    case ICON_TYPE.ACTIVE:
      return (
        <AIFeatureActive {...props} ref={ref} />
      );

    case ICON_TYPE.DISABLED:
      return (
        <AIFeatureDisabled {...props} ref={ref} />
      );

    case ICON_TYPE.NORMAL:
    default:
      return (
        <AIFeatureNormal {...props} ref={ref} />
      );
  }
});

AIFeature.propTypes = {
  type: PT.oneOf(Object.values(ICON_TYPE))
};

export default AIFeature;
