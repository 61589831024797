import React, { memo } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import TableCell from '../TableCell';
import { COL_MAX_WIDTH_PX } from '../../constants';

const RawText = ({ className, children, title, typographyProps, suggestions }) => (
  <TableCell className={className} suggestions={suggestions}>
    <Typography
      variant="body2"
      noWrap
      title={title || children}
      {...typographyProps}
    >
      {children}
    </Typography>
  </TableCell>
);

RawText.propTypes = {
  className: PT.string,
  title: PT.oneOfType([
    PT.string,
    PT.number
  ]),
  typographyProps: PT.object,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  suggestions: PT.object,
};

export default styled(memo(RawText))`
  max-width: ${COL_MAX_WIDTH_PX}px;
`;
